import CMPErrorHelperText from '../cmpErrorHelperText';
import { getCharacterLimit } from '../../utils/configureElementUtil';
import {
    BaseTextFieldProps,
    className,
    CMPTextFieldContainer,
    StyledTextField,
    SupportingText,
    TextVariation,
    Theme,
    TooltipComponent,
    StyledInputAdornment
} from './cmpTextField';
import React from 'react';
import { SxProps } from '@mui/material';

export interface DefaultTextFieldProps extends BaseTextFieldProps {
    variation?: TextVariation.DEFAULT | TextVariation.NUMBER;
    maxLength?: number;
    error?: boolean;
    helperText?: string;
    tooltipMessage?: string;
    placeholder?: string;
    label?: string;
    name?: string;
    rows?: number;
    required?: boolean;
    id?: string;
    shrink?: boolean;
    displayCharacterLimit?: boolean;
    supportingTexts?: string;
    rowHeight?: number;
    noBorder?: boolean;
    inputRef?: React.Ref<HTMLInputElement | HTMLTextAreaElement>;
    startInputAdornment?: string;
    startInputAdornmentStyles?: SxProps;
}

const DefaultTextField = (props: DefaultTextFieldProps) => {
    const {
        maxLength = 128,
        variation,
        onChange,
        placeholder,
        label,
        disabled,
        name,
        rows = 1,
        fullWidth = true,
        helperText,
        error = false,
        required,
        tooltipMessage,
        sx,
        id,
        supportingTexts,
        theme = Theme.LIGHT,
        value,
        shrink = true,
        inputRef,
        displayCharacterLimit = false,
        rowHeight = 52,
        backgroundTransparent,
        noBorder,
        onBlur,
        onFocus,
        startInputAdornment,
        startInputAdornmentStyles
    } = props;

    return (
        <CMPTextFieldContainer
            sx={{ ...sx, width: fullWidth ? '100%' : 'auto' }}
        >
            <StyledTextField
                inputRef={inputRef}
                onBlur={onBlur}
                onFocus={onFocus}
                type={variation === TextVariation.NUMBER ? 'number' : 'text'}
                rows={rows}
                rowHeight={rowHeight}
                sx={{
                    background: backgroundTransparent
                        ? 'rgba(0, 0, 16, 0.5)'
                        : theme === Theme.DARK
                        ? 'rgb(55, 55, 68)'
                        : 'inherit'
                }}
                id={id}
                className={className([
                    theme === Theme.DARK
                        ? 'cmp-text-field text-theme-dark'
                        : 'cmp-text-field text-theme-light',
                    disabled ? 'text-field-disabled' : 'text-field-enabled',
                    noBorder ? 'cmp-text-field-border-none' : '',
                    label ? '' : 'no-dropdown-label'
                ])}
                placeholder={placeholder}
                label={
                    <>
                        {label}
                        <TooltipComponent
                            theme={theme}
                            tooltipMessage={tooltipMessage}
                        />
                    </>
                }
                name={name}
                required={required}
                multiline={(rows as number) > 1}
                fullWidth={fullWidth}
                onChange={(e) => onChange(e.target.value.trimStart() as string)}
                inputProps={{
                    maxLength: maxLength
                }}
                InputProps={{
                    startAdornment: startInputAdornment ? (
                        <StyledInputAdornment
                            darkTheme={theme === Theme.DARK}
                            disabled={disabled}
                            sx={startInputAdornmentStyles}
                            position="start"
                        >
                            {startInputAdornment}
                        </StyledInputAdornment>
                    ) : null,
                    endAdornment: displayCharacterLimit ? (
                        //@ts-ignore
                        <StyledInputAdornment
                            position="end"
                            darkTheme={theme === Theme.DARK}
                            disabled={disabled}
                        >
                            {getCharacterLimit(maxLength, value ?? 0)}
                        </StyledInputAdornment>
                    ) : null
                }}
                InputLabelProps={{ shrink: shrink }}
                disabled={disabled}
                error={error}
                variant="outlined"
                value={value}
            />
            <CMPErrorHelperText text={helperText} theme={theme} />
            <SupportingText
                isDarkTheme={theme === Theme.DARK}
                text={supportingTexts}
            />
        </CMPTextFieldContainer>
    );
};

export default DefaultTextField;
