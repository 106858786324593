import React, { useMemo, useState } from 'react';
import { cloneDeep } from 'lodash';
import { useSelector } from 'react-redux';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary
} from '../../../../components/accordion';
import { Box, Grid, Typography } from '@mui/material';
import { pxToRem } from '../../../../utils/stylesUtils';
import PropertySectionCheckBox from './propertySectionCheckBox';
import { shallowSearch } from '../../../../utils/searchUtil';
import { selectEvolve2OperationLoader } from '../../../../redux/evolve2/reducer';
import CMPTextField, {
    TextVariation
} from '../../../../components/textFields/cmpTextField';

interface Option {
    canUnselect: boolean;
    label: string;
    valueId: string;
}

interface PropertySectionAccordionWithCheckboxProps {
    value: string[];
    name: string;
    options: Option[];
    handleChange: (ids: string[]) => void;
}

const PropertySectionAccordionWithCheckbox = (
    props: PropertySectionAccordionWithCheckboxProps
) => {
    const { value, options, name, handleChange } = props;
    const [openAccordion, setOpenAccordion] = useState(false);
    const [searchText, setSearchText] = useState('');

    const evolve2OperationLoader = useSelector(selectEvolve2OperationLoader);

    const handleCheckboxChange = (valueId: string) => {
        if ((value as string[]).includes(valueId)) {
            const updatedSelectedApps = (value as string[]).filter(
                (id: string) => id !== valueId
            );
            handleChange(updatedSelectedApps);
        } else {
            handleChange([...(value as string[]), valueId]);
        }
    };

    const filteredAppsList = useMemo(() => {
        let appsList = cloneDeep(options);

        if (searchText && options) {
            appsList = shallowSearch(options, searchText, ['label']);
        }
        const selectedApps: Option[] = [];
        const unSelectedApps: Option[] = [];
        appsList?.forEach((app: Option) => {
            if (value.includes(app.valueId)) {
                selectedApps.push(app);
            } else {
                unSelectedApps.push(app);
            }
        });

        appsList = [
            ...selectedApps.sort((a: Option, b: Option) => {
                return Number(a.valueId) - Number(b.valueId);
            }),
            ...unSelectedApps.sort(
                (a: Option, b: Option) => Number(a.valueId) - Number(b.valueId)
            )
        ];

        return appsList;
    }, [searchText, evolve2OperationLoader]);

    return (
        <Accordion
            expanded={openAccordion}
            onChange={() =>
                setOpenAccordion((prevState) => {
                    return !prevState;
                })
            }
            sx={{ border: 0 }}
        >
            <AccordionSummary>
                <Typography>{`${name} (${
                    (value as string[]).length
                })`}</Typography>
            </AccordionSummary>
            <AccordionDetails>
                <CMPTextField
                    variation={TextVariation.SEARCH}
                    enableDebouce
                    value={searchText}
                    sx={{
                        width: '30%',
                        margin: `${pxToRem(16)} 0`
                    }}
                    placeholder="Search"
                    onChange={setSearchText}
                />
                <Box
                    sx={{
                        borderRadius: pxToRem(7),
                        border: '1px solid #000010',
                        padding: pxToRem(16)
                    }}
                >
                    <Grid container>
                        {filteredAppsList?.map((option: Option) => {
                            return (
                                <Grid
                                    item
                                    xs={6}
                                    sm={4}
                                    md={3}
                                    lg={2}
                                    key={option.valueId}
                                >
                                    <PropertySectionCheckBox
                                        disabled={!option.canUnselect}
                                        checked={value.includes(option.valueId)}
                                        handleChange={() =>
                                            handleCheckboxChange(option.valueId)
                                        }
                                        label={option.label}
                                    />
                                </Grid>
                            );
                        })}
                    </Grid>
                </Box>
            </AccordionDetails>
        </Accordion>
    );
};

export default PropertySectionAccordionWithCheckbox;
