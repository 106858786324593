import { Grid } from '@mui/material';
import { useFormik } from 'formik';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import Button, { ButtonSize, ButtonVariation } from '../../components/button';
import cmpTexts from '../../locale/en';
import { editAsset } from '../../redux/assets/action';
import {
    existingAssetsNames,
    selectAssetOperationLoadingState
} from '../../redux/assets/reducer';
import { Asset } from '../../redux/assets/model';
import { LoadingStatus } from '../../redux/common/state';
import { pxToRem } from '../../utils/stylesUtils';
import AssetsAssociationTable from './assetsAssociationsTable';
import CMPDialog, {
    CMPDialogActions,
    CMPDialogContent,
    CMPDialogTitle
} from '../../components/cmpDialog';
import CMPTextField from '../../components/textFields/cmpTextField';

interface Props {
    heading: string;
    asset: Asset;
    property_id: string | undefined;
    handleClose: (show: boolean) => void;
    isEdit?: boolean;
}

const EditAssetModal = (props: Props) => {
    const {
        handleClose,
        asset,
        property_id = '',
        isEdit = false,
        heading
    } = props;

    const assetOperationLoader = useSelector(selectAssetOperationLoadingState);
    const existingNames = useSelector(existingAssetsNames);

    const closeModal = () => handleClose(false);
    const dispatch = useDispatch();

    const editAssetsSchema = Yup.object().shape({
        Name: Yup.string()
            .required('*Required')
            .trim()
            .notOneOf(
                existingNames?.filter((name) => name !== asset.name) ?? [],
                cmpTexts.assets.nameAlreadyExists
            )
    });

    const formik = useFormik({
        initialValues: {
            Name: asset.name
        },
        validationSchema: editAssetsSchema,
        onSubmit: () => {}
    });

    const onSubmit = () => {
        if (asset && asset?.id) {
            dispatch(
                editAsset(asset.id, formik.values.Name.trim(), property_id)
            );
        }
    };

    useEffect(() => {
        if (assetOperationLoader === LoadingStatus.DONE) {
            handleClose(false);
        }
    }, [assetOperationLoader]);

    return (
        <CMPDialog
            onClose={closeModal}
            PaperProps={{
                style: {
                    background: '#F5F5F6'
                }
            }}
        >
            <CMPDialogTitle sx={{ fontSize: pxToRem(32) }}>
                {heading}
            </CMPDialogTitle>
            <CMPDialogContent>
                {isEdit && (
                    <Grid
                        container
                        style={{ padding: `${pxToRem(10)} 0 ${pxToRem(24)} 0` }}
                    >
                        <CMPTextField
                            id="outlined-helperText"
                            label="Edit"
                            onChange={(val) =>
                                formik.setFieldValue('Name', val)
                            }
                            value={formik.values.Name?.trimStart()}
                            error={Boolean(formik.errors.Name)}
                            helperText={formik.errors.Name}
                            fullWidth
                        />
                    </Grid>
                )}
                {asset.associations?.length > 0 && (
                    <AssetsAssociationTable associations={asset.associations} />
                )}
            </CMPDialogContent>
            {isEdit && (
                <CMPDialogActions>
                    <Button
                        buttonVariant={ButtonVariation.OUTLINED_BLACK}
                        buttonSize={ButtonSize.LARGE}
                        onClick={closeModal}
                    >
                        {cmpTexts.assets.cancel}
                    </Button>
                    <Button
                        type="submit"
                        buttonVariant={ButtonVariation.CONTAINED}
                        onClick={onSubmit}
                        disabled={!(formik.isValid && formik.dirty)}
                    >
                        {cmpTexts.assets.save}
                    </Button>
                </CMPDialogActions>
            )}
        </CMPDialog>
    );
};

export default EditAssetModal;
