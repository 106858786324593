import { Grid, Typography } from '@mui/material';
import React, { useState } from 'react';
import Button, {
    ButtonVariation,
    ButtonSize
} from '../../../../components/button';
import { pxToRem } from '../../../../utils/stylesUtils';
import { Mode, RequiredText } from '../manageZones';
import {
    EventZone,
    StandardZone,
    ZoneType
} from '../../../../redux/zones/model';
import CMPDropDown from '../../../../components/cmpDropDown';
import { useFormik } from 'formik';
import DateAndTimePicker from './dateAndTimePicker';
import { zoneValidationSchema } from '../zoneUtils';
import ZoneRoomSelectorSection from './zoneRoomSelectorSection';
import { useDispatch, useSelector } from 'react-redux';
import { createZone, editZone } from '../../../../redux/zones/action';
import { CreateUpdateZone } from '../../../../redux/zones/actionPayload';
import CMPDialog, {
    CMPDialogActions,
    CMPDialogContent,
    CMPDialogTitle
} from '../../../../components/cmpDialog';
import { selectExistingZoneNames } from '../../../../redux/zones/reducer';
import cmpTexts from '../../../../locale/en';
import {
    selectEditZoneAllowed,
    selectEditZoneRoomsAllowed
} from '../../../../redux/userOperations/reducer';
import { DisableableBox } from '../../../../components/disableableBox';
import CMPTextField from '../../../../components/textFields/cmpTextField';

const getHeader = (zoneType: ZoneType, actionType: Mode) => {
    switch (actionType) {
        case Mode.Create:
            return zoneType === ZoneType.Event ? 'Create Event' : 'Create Zone';
        case Mode.Edit:
            return zoneType === ZoneType.Event ? 'Edit Event' : 'Edit Zone';
        default:
            return 'View Rooms';
    }
};

const ZonesAndEventsOperationModal = (props: {
    setIsModalOpen: (isAddCommentModalOpen: boolean) => void;
    zoneType: ZoneType;
    actionType: Mode;
    zone: EventZone | StandardZone;
}) => {
    const { setIsModalOpen, zoneType, actionType, zone } = props;
    const [isRoomOverlapping, setIsRoomOverlapping] = useState(false);
    const [deviceIds, setDeviceIds] = useState<string[]>([]);
    const [isRoomsEdited, setIsRoomsEdited] = useState(false);
    const dispatch = useDispatch();

    const existingZoneNames = useSelector(selectExistingZoneNames);
    const canEditZoneDetails = useSelector(selectEditZoneAllowed);
    const canEditZoneRooms = useSelector(selectEditZoneRoomsAllowed);

    const removeCurrentEditingZoneName = (
        existingZoneNames: string[]
    ): string[] => {
        if (actionType === Mode.Edit) {
            return existingZoneNames.filter(
                (name: string) => name !== zone.name
            );
        }
        return existingZoneNames;
    };

    const formik = useFormik<EventZone | StandardZone>({
        initialValues: zone,
        validateOnBlur: true,
        validateOnMount: true,
        validationSchema: zoneValidationSchema(
            removeCurrentEditingZoneName(existingZoneNames)
        ),
        onSubmit: () => {}
    });

    const onSaveClick = () => {
        const createPayload: CreateUpdateZone = {
            rooms_attached: deviceIds,
            propertyId: formik.values.property_id,
            type: formik.values.type,
            visibility_type: formik.values.visibility_type,
            description: formik.values.description,
            name: formik.values.name,
            zoneId: formik.values.id
        };

        if (zoneType === ZoneType.Standard) {
            if (actionType === Mode.Create) {
                dispatch(createZone(createPayload));
            } else {
                dispatch(editZone(createPayload));
            }
        } else {
            createPayload.start_time = (formik.values as EventZone).start_time;
            createPayload.end_time = (formik.values as EventZone).end_time;
            if (actionType === Mode.Create) {
                dispatch(createZone(createPayload));
            } else {
                dispatch(editZone(createPayload));
            }
        }
    };

    const handleClose = () => {
        setIsModalOpen(false);
    };

    return (
        <CMPDialog onClose={handleClose}>
            <CMPDialogTitle sx={{ fontSize: pxToRem(28), fontWeight: '800' }}>
                {getHeader(zoneType, actionType)}
            </CMPDialogTitle>
            <CMPDialogContent>
                <DisableableBox disabled={!canEditZoneDetails}>
                    <Grid
                        container
                        item
                        spacing={2}
                        rowSpacing={2}
                        sx={{ marginTop: pxToRem(-10) }}
                    >
                        <Grid item xs={8}>
                            <CMPTextField
                                id="name"
                                value={formik.values.name}
                                onChange={(v) =>
                                    formik.setFieldValue('name', v)
                                }
                                error={Boolean(formik.errors.name)}
                                label="Zone Name"
                                required
                                helperText={formik.errors.name}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <CMPDropDown
                                required
                                label="Zone Type*"
                                error={Boolean(formik.errors.visibility_type)}
                                options={[
                                    { value: 'Private', label: 'Private' },
                                    { value: 'Public', label: 'Public' }
                                ]}
                                value={formik.values.visibility_type}
                                helperText=""
                                onChange={(value: string) =>
                                    formik.setFieldValue(
                                        'visibility_type',
                                        value
                                    )
                                }
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <CMPTextField
                                id="description"
                                value={formik.values.description}
                                onChange={(v) =>
                                    formik.setFieldValue('description', v)
                                }
                                error={Boolean(formik.errors.description)}
                                label={
                                    zoneType === ZoneType.Standard
                                        ? 'Zone Description'
                                        : 'Event Description'
                                }
                                required
                                helperText={formik.errors.description}
                            />
                        </Grid>
                        {zoneType === ZoneType.Event && (
                            <>
                                <DateAndTimePicker
                                    required
                                    error={(formik?.errors as any)?.start_time}
                                    label="Start Date"
                                    epochDate={
                                        (formik.values as EventZone).start_time
                                    }
                                    setEpochDate={(val: number) =>
                                        formik.setFieldValue('start_time', val)
                                    }
                                />
                                <DateAndTimePicker
                                    required
                                    label="End Date"
                                    error={(formik?.errors as any)?.end_time}
                                    epochDate={
                                        (formik.values as EventZone).end_time
                                    }
                                    setEpochDate={(val: number) =>
                                        formik.setFieldValue('end_time', val)
                                    }
                                    minimumDate={
                                        new Date(
                                            (
                                                formik.values as EventZone
                                            ).start_time
                                        )
                                    }
                                />
                            </>
                        )}
                    </Grid>
                </DisableableBox>
                <DisableableBox disabled={!canEditZoneRooms}>
                    <Typography
                        sx={{
                            fontSize: pxToRem(18),
                            fontWeight: 500,
                            lineHeight: pxToRem(24),
                            letterSpacing: 0,
                            marginTop: pxToRem(20),
                            marginBottom:
                                actionType === Mode.Create ? pxToRem(12) : 0
                        }}
                    >
                        {cmpTexts.zones.selectRooms}
                    </Typography>
                    <ZoneRoomSelectorSection
                        isEditMode={actionType === Mode.Edit}
                        zone={formik.values}
                        onSelectRooms={(isEdited, hasError, deviceIds) => {
                            setIsRoomOverlapping(hasError);
                            setDeviceIds(deviceIds);
                            setIsRoomsEdited(isEdited);
                        }}
                    />
                </DisableableBox>

                <RequiredText>*Required Field</RequiredText>
            </CMPDialogContent>
            <CMPDialogActions>
                <Button
                    buttonVariant={ButtonVariation.OUTLINED_BLACK}
                    buttonSize={ButtonSize.MEDIUM}
                    onClick={() => setIsModalOpen(false)}
                >
                    Cancel
                </Button>
                <Button
                    buttonVariant={ButtonVariation.CONTAINED}
                    buttonSize={ButtonSize.MEDIUM}
                    disabled={
                        !formik.isValid ||
                        isRoomOverlapping ||
                        !(isRoomsEdited || formik.dirty)
                    }
                    onClick={() => {
                        onSaveClick();
                        setIsModalOpen(false);
                    }}
                >
                    Save
                </Button>
            </CMPDialogActions>
        </CMPDialog>
    );
};

export default ZonesAndEventsOperationModal;
