import { Options } from '../../components/cmpDropDown';
import { AccessDetails, MediaType } from '../element/model';

export const emptyPropertyFieldValues: PropertyFieldValues = {
    property_group_name: '',
    property_id: '',
    property_name: '',
    property: {
        default: []
    },
    property_group_id: '',
    property_type: ''
};

export interface PropertyFieldValueData {
    property_field_id: string;
    values: PropertyFieldValue;
}
export interface UpdateFieldResponse extends PropertyFieldValueData {
    id: string;
}

export interface Condition {
    key: string;
    operator: string;
    value: any;
}

interface ConditionGroup {
    conditions: Condition[];
}
interface VisibleCondition extends ConditionGroup {
    isVisible: boolean;
}
interface RequiredCondition extends ConditionGroup {
    isRequired: boolean;
}
interface EditableCondition extends ConditionGroup {
    isEditable: boolean;
    message: string;
}

interface MessagingCondition extends ConditionGroup {
    message: string;
}
interface ValueCondition extends ConditionGroup {
    value: any;
}

export enum PropertyFieldValueType {
    String = 'String',
    Number = 'Number',
    Boolean = 'Boolean',
    Complex = 'Complex',
    SectionBoolean = 'SectionBoolean',
    DropDown = 'DropDown',
    Integer = 'Integer',
    Color = 'Color',
    Time = 'Time',
    FAQ = 'FAQ',
    HorizontalOptionSelector = 'HorizontalOptionSelector',
    InfoMessage = 'InfoMessage',
    MultiSelectBoolean = 'MultiSelectBoolean',
    ConsentCheckbox = 'ConsentCheckbox',
    FileAsString = 'FileAsString',
    AccordionDisplayMultiOptionSelector = 'AccordionDisplayMultiOptionSelector',
    ScreenSaverContent = 'ScreenSaverContent'
}

export interface FAQ {
    faq: IndividualFaq[];
}

export type MultiSelectBooleanValue = {
    [key: string]: boolean;
};

export interface FileAsStringValue {
    name: string;
    content: string;
}

export type PropertyFieldValue =
    | string
    | null
    | number
    | boolean
    | FAQ
    | MultiSelectBooleanValue
    | FileAsStringValue
    | string[]
    | ScreenSaverContentValue;

export interface ScreenSaverContentValue {
    type: MediaType;
    assets: string[];
}
export interface MutliSelectBooleanOption {
    key: string;
    name: string;
    defaultValue: boolean;
}
export interface PropertyField {
    id: string;
    name: string;
    key: string;
    description: string;
    value_type: PropertyFieldValueType;
    children: PropertyField[] | null;
    value: PropertyFieldValue;
    access_details?: AccessDetails;
    configuration?: {
        maxValue?: number;
        tileType?: string;
        minValue?: number;
        helperText?: string;
        widthFactor?: number;
        options?: any[];
        multiSelectBooleanOptions: MutliSelectBooleanOption[];
        startNextRow?: boolean;
        toolTip?: string;
        format?: string;
        isRequired?: boolean;
        isSensitive?: boolean;
        showDescription?: boolean;
        maxAllowedFAQs: number;
        infoStyle?: string;
        conditions?: {
            visibleConditions?: VisibleCondition[];
            editableConditions?: EditableCondition[];
            requiredConditions?: RequiredCondition[];
            valueConditions?: ValueCondition[];
            messagingConditions?: MessagingCondition[];
        };
        allowedFileTypes?: string[];
        maxFileSize?: number;
        fileUploadInfoMessage?: string;
        videoConfig?: ScreenSaverAssetConfig;
        screenSaverTypeConfig?: {
            name?: string;
            options?: Options[];
        };
        imageConfig?: ScreenSaverAssetConfig;
        defaultScreenSaverImage: string;
    };
}

interface ScreenSaverAssetConfig {
    allowedFileTypes: string[];
    maxAllowed: number;
    maxFileSize: number;
    name: string;
    toolTip: string;
    fileUploadInfoMessage: string;
}
export interface PlatformSection {
    [platform: string]: PropertyField[];
}
interface PropertyFieldSection {
    platforms?: PlatformSection;
    default: PropertyField[];
    themes?: PropertyField[];
}
export interface PropertyFieldValues {
    property_group_name: string;
    property_name: string;
    property_id: string;
    property: PropertyFieldSection;
    property_group_id: string;
    property_type: string;
}

export interface UpdatePropertyFieldValuesBody {
    fields: PropertyFieldValueData[];
    property_id: string;
}

export interface IndividualFaq {
    question: string;
    answer: string;
}
