import AddIcon from '@mui/icons-material/Add';
import React, { useEffect, useState } from 'react';
import ConfirmationModal from '../../../components/confirmationModal';
import { Box, styled } from '@mui/material';
import cmpTexts from '../../../locale/en';
import Button from '../../../components/button';
import { pxToRem } from '../../../utils/stylesUtils';
import { isEmpty } from 'lodash';
import { FAQ, IndividualFaq } from '../../../redux/propertyValue/model';
import IndividualFAQComponent from './individualFAQComponent';
import {
    arrayMove,
    SortableContainer,
    SortableElement,
    SortableHandle
} from 'react-sortable-hoc';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';

interface Props {
    faqData: FAQ;
    setShowSaveBar: (
        val: boolean
    ) => void | React.Dispatch<React.SetStateAction<boolean>>;

    showSaveBar: boolean;
    setHasError: (setHasError: boolean) => void;
    onFaqUpdated?: (value: FAQ) => void;
    maxAllowedFAQs?: number;
    description: string;
    showDescription?: boolean;
    deleteFAQModalHeader: string;
    deleteFAQModalDescription: string;
    viewOnly?: boolean;
}

interface SortableContainerProps {
    children: Element[] | React.ReactNode;
}

const DragIcon = styled(DragIndicatorIcon)(() => ({
    color: 'white',
    position: 'absolute',
    left: pxToRem(2),
    zIndex: 1
}));

const DescriptionContainer = styled('div')(() => ({
    display: 'flex',
    marginTop: 10,
    marginBottom: 20
}));

const DescriptionText = styled('div')(() => ({
    fontSize: '1rem',
    fontWeight: 500
}));

const SortableLists = SortableContainer((props: SortableContainerProps) => {
    return <Box>{props.children}</Box>;
});

const SortableItem = SortableElement((props: SortableContainerProps) => {
    return <>{props.children}</>;
});

const DragHandle = SortableHandle(() => {
    return <DragIcon />;
});

const ButtonStyle = styled('div')(() => ({
    margin: `${pxToRem(24)} 0 ${pxToRem(24)} -${pxToRem(16)}`,
    justifyContent: 'center',
    display: 'flex'
}));

const FAQSection = (props: Props) => {
    const {
        faqData,
        setShowSaveBar,
        showSaveBar,
        setHasError,
        maxAllowedFAQs = -1,
        description,
        showDescription = false,
        onFaqUpdated = () => {},
        deleteFAQModalHeader,
        deleteFAQModalDescription,
        viewOnly = false
    } = props;

    const [faqValues, setFaqValues] = useState(faqData.faq);
    const [faqErrors, setErrors] = useState<any[] | never[]>([]);
    const [deleteFAQIndex, setDeleteFAQIndex] = useState(-1);

    const emptyArray = new Array(faqValues?.length).fill(undefined);
    const faqExpanded = new Array(faqValues?.length).fill(false);

    const [expanded, setExpanded] = useState<boolean[]>(faqExpanded);

    useEffect(() => {
        setFaqValues(faqData.faq);
    }, [faqData.faq]);

    const handleDelete = (index: number) => (e: React.SyntheticEvent) => {
        e.stopPropagation();
        if (
            isEmpty(faqValues[index].question) &&
            isEmpty(faqValues[index].answer)
        )
            handleFAQDelete(index);
        else setDeleteFAQIndex(index);
    };

    const handleFAQDelete = (index: number) => {
        const expandedData = [...expanded];
        expandedData.splice(index, 1);
        setExpanded(expandedData);
        const faqData = faqValues;
        faqData.splice(index, 1);
        setFaqValues(faqData);
        setShowSaveBar(true);
        const error = [...faqErrors];
        error.splice(index, 1);
        setErrors(error);
        onFaqUpdated({ faq: faqValues });
        setDeleteFAQIndex(-1);
    };

    const handleCloseDeleteModal = () => {
        setDeleteFAQIndex(-1);
    };

    const handleChange =
        (i: number, type: 'question' | 'answer') => (value: string) => {
            const faqData = [...faqValues];
            if (faqData[i][type] !== value.trimStart()) {
                faqData[i][type] = value;
                setFaqValues(faqData);
                setShowSaveBar(true);
                onFaqUpdated({ faq: faqValues });
                let error = [...faqErrors];
                if (isEmpty(value)) {
                    if (error[i] === undefined) {
                        error[i] = {};
                    }

                    error[i][type] = '*Required Field';
                    setHasError(true);
                    setErrors(error);
                } else if (!isEmpty(error[i])) {
                    delete error[i][type];
                    if (isEmpty(error[i])) {
                        error[i] = undefined;
                    }
                    const hasAnyError = error.findIndex((i) => i !== undefined);
                    if (hasAnyError === -1) {
                        error = [];
                    }
                    setErrors(error);
                }
            }
        };

    const handleBlur = () => {
        onFaqUpdated({ faq: faqValues });
    };

    const onSortEnd = ({
        oldIndex,
        newIndex
    }: {
        oldIndex: number;
        newIndex: number;
    }) => {
        if (oldIndex === newIndex) return;

        if (!showSaveBar) {
            setShowSaveBar(true);
        }

        const newFaq = arrayMove(faqValues, oldIndex, newIndex);
        if (faqErrors.length > 0) {
            const newError = arrayMove(faqErrors, oldIndex, newIndex);
            setErrors(newError);
        }
        const expandedFAQ = arrayMove(expanded, oldIndex, newIndex);
        setExpanded(expandedFAQ);
        setFaqValues(newFaq);
        onFaqUpdated({ faq: newFaq });
    };

    useEffect(() => {
        if (!showSaveBar) {
            setHasError(false);
            setFaqValues(faqData.faq);
            setErrors([]);
        }
    }, [showSaveBar]);

    useEffect(() => {
        if (faqErrors.length > 0) {
            const hasErrors =
                faqErrors.findIndex((i) => i !== undefined) === -1;
            if (hasErrors) {
                setErrors([]);
            }
            setHasError(true);
        } else {
            setHasError(false);
        }
    }, [faqErrors]);

    const handleAdd = () => {
        const faqData = [...faqValues];
        faqData.push({ question: '', answer: '' });
        const expandedData = [...expanded];
        expandedData.push(true);
        setExpanded(expandedData);
        onFaqUpdated({ faq: faqData });
        let errors = [...faqErrors];
        if (errors.length === 0) {
            errors = emptyArray;
        }
        errors.push({ question: '*Required Field', answer: '*Required Field' });
        setErrors(errors);
    };

    const accordionOnCLick = (index: number) => (e: React.SyntheticEvent) => {
        e.stopPropagation();
        const expandedData = [...expanded];
        expandedData[index] = !expandedData[index];
        setExpanded(expandedData);
    };

    return (
        <>
            {showDescription && (
                <DescriptionContainer>
                    <DescriptionText>{description}</DescriptionText>
                </DescriptionContainer>
            )}
            <SortableLists useDragHandle={true} axis="y" onSortEnd={onSortEnd}>
                {faqValues?.map((item: IndividualFaq, index: number) => {
                    return (
                        <SortableItem index={index} key={index}>
                            <IndividualFAQComponent
                                dragHandle={<DragHandle />}
                                viewOnly={viewOnly}
                                id={index}
                                expanded={expanded[index]}
                                faqErrors={Boolean(faqErrors?.[index])}
                                accordionOnCLick={accordionOnCLick}
                                item={item}
                                handleDelete={handleDelete}
                                handleChange={handleChange}
                                handleBlur={handleBlur}
                                errorQuestionText={faqErrors?.[index]?.question}
                                errorAnswerText={faqErrors?.[index]?.answer}
                            />
                        </SortableItem>
                    );
                })}
            </SortableLists>
            {!viewOnly &&
                (maxAllowedFAQs === -1 ||
                    faqValues.length < maxAllowedFAQs) && (
                    <ButtonStyle>
                        <Button
                            sx={{
                                fontSize: '1rem',
                                fontWeight: 'bold',
                                color: '#F01446'
                            }}
                            startIcon={<AddIcon />}
                            onClick={handleAdd}
                        >
                            {cmpTexts.faqSection.AddButton}
                        </Button>
                    </ButtonStyle>
                )}
            {deleteFAQIndex > -1 && (
                <ConfirmationModal
                    title={deleteFAQModalHeader}
                    description={deleteFAQModalDescription}
                    onPositiveButtonClick={() =>
                        handleFAQDelete(deleteFAQIndex)
                    }
                    onClose={handleCloseDeleteModal}
                    positiveButtonText={
                        cmpTexts.faqSection.deleteModalSbmitButton
                    }
                    negativeButtonText={
                        cmpTexts.faqSection.deleteModalCancelButton
                    }
                />
            )}
        </>
    );
};

export default FAQSection;
