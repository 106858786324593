import {
    Box,
    Collapse,
    IconButton,
    Paper,
    styled,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    TableSortLabel,
    Typography
} from '@mui/material';
import { includes } from 'lodash';
import React, { useState } from 'react';
import { pxToRem } from '../../../utils/stylesUtils';
import { ReactComponent as TriangleUp } from '../../../assets/svg/triangleFilledUp.svg';
import { ReactComponent as BottomChevronTable } from '../../../assets/svg/bottomChevronTable.svg';
import TimeDisplay from '../../../components/timeDisplay';
import ActionButtons from './components/actionButtons';
import { EventZone, ZoneType } from '../../../redux/zones/model';
import ViewRooms from './components/viewRooms';
import PlatformPanel from './components/platformPanel';
import { getComparator, CompareOrder } from '../../../utils/comparatorUtil';
import { ZoneAndEventTableProps } from './zonesTable';

const StyledTableCell = styled(TableCell)(() => ({
    maxHeight: pxToRem(80),
    fontSize: pxToRem(16),
    fontWeight: 500,
    lineHeight: pxToRem(21),
    borderBottom: 'none',
    color: '#000010',
    paddingRight: pxToRem(20)
}));

interface Data {
    id: number;
    name: string;
    visibility_type: string;
    rooms: string;
    description: string;
    action: string;
    start_time: number;
    end_time: number;
}

interface HeadCell {
    id: keyof Data;
    label: string;
    width: string;
}

const headCells: readonly HeadCell[] = [
    {
        id: 'name',
        label: 'Zone Name',
        width: '18%'
    },
    {
        id: 'description',
        label: 'Event Description',
        width: '22%'
    },
    {
        id: 'visibility_type',
        label: 'Type',
        width: '10%'
    },
    {
        id: 'rooms',
        label: 'Rooms',
        width: '6%'
    },
    {
        id: 'start_time',
        label: 'Start Date',
        width: '15%'
    },
    {
        id: 'end_time',
        label: 'End Date',
        width: '15%'
    },
    { id: 'action', label: '', width: '12%' }
];

const EventsTable = (props: ZoneAndEventTableProps) => {
    const {
        filteredItems,
        page,
        setPage,
        propertyId,
        setShouldResetForm,
        shouldResetForm,
        updateSubmitPayload,
        setShowSaveBar,
        expandedRows,
        setExpandedRows,
        autoAffectedFieldIds,
        setHasError,
        zoneToScreenSaverAssets,
        setZoneToScreenSaverAssets,
        propertyLogoAssetId
    } = props;

    const [order, setOrder] = useState<CompareOrder>('asc');
    const [orderBy, setOrderBy] = useState<keyof Data | ''>('');
    const rowsPerPage = 10;

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };
    const handleRequestSort = (
        event: React.MouseEvent<unknown>,
        property: keyof Data
    ) => {
        if (orderBy === property && order === 'asc') {
            setOrder('desc');
        } else if (orderBy === property && order === 'desc') {
            setOrder('none');
        } else setOrder('asc');

        setOrderBy(property);
    };

    const createSortHandler =
        (property: keyof Data) => (event: React.MouseEvent<unknown>) => {
            handleRequestSort(event, property);
        };

    const visibleRows = React.useMemo(() => {
        const modifiedActivityLogs = [...filteredItems];

        if (order === 'none' || orderBy === '') {
            return modifiedActivityLogs.slice(
                page * rowsPerPage,
                page * rowsPerPage + rowsPerPage
            );
        }
        return modifiedActivityLogs
            .slice()
            .sort(getComparator(order, orderBy))
            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
    }, [order, orderBy, page, filteredItems]);

    const toggleRow = (rowId: string) => {
        const newExpandedRows: string[] = [...expandedRows];
        const isRowExpanded = includes(newExpandedRows, rowId);

        if (isRowExpanded) {
            const index = newExpandedRows.indexOf(rowId);
            newExpandedRows.splice(index, 1);
        } else {
            newExpandedRows.push(rowId);
        }

        setExpandedRows(newExpandedRows);
    };

    const emptyRows = rowsPerPage - visibleRows.length;

    return (
        <Box sx={{ width: '100%', borderRadius: pxToRem(20) }}>
            <Paper sx={{ width: '100%', mb: 2, borderRadius: pxToRem(20) }}>
                <TableContainer sx={{ borderRadius: pxToRem(20) }}>
                    <Table
                        sx={{
                            minWidth: 750,
                            tableLayout: 'auto'
                        }}
                        size={'medium'}
                    >
                        <TableHead>
                            <TableRow
                                sx={{
                                    '& th': {
                                        color: 'white',
                                        backgroundColor: '#171725'
                                    }
                                }}
                            >
                                <TableCell
                                    sx={{
                                        padding: `0 ${pxToRem(10)}`,
                                        minWidth: pxToRem(60)
                                    }}
                                ></TableCell>
                                {headCells.map((headCell) => (
                                    <TableCell
                                        sx={{
                                            padding: `${pxToRem(20)} ${pxToRem(
                                                16
                                            )} ${pxToRem(20)} 0`,
                                            width: headCell.width
                                        }}
                                        key={headCell.id}
                                        align="left"
                                    >
                                        <Box sx={{ display: 'flex' }}>
                                            <Typography
                                                sx={{
                                                    color: '#FFF',
                                                    fontSize: pxToRem(16),
                                                    lineHeight: pxToRem(16),
                                                    fontWeight: 700
                                                }}
                                            >
                                                {headCell.label}
                                            </Typography>
                                            {headCell.id !== 'action' &&
                                                headCell.id !==
                                                    'description' && (
                                                    <TableSortLabel
                                                        sx={{
                                                            marginLeft:
                                                                pxToRem(12)
                                                        }}
                                                        active={
                                                            orderBy ===
                                                            headCell.id
                                                        }
                                                        onClick={createSortHandler(
                                                            headCell.id
                                                        )}
                                                        IconComponent={() => (
                                                            <Box
                                                                sx={{
                                                                    display:
                                                                        'flex',
                                                                    gap: pxToRem(
                                                                        4
                                                                    ),
                                                                    flexDirection:
                                                                        'column'
                                                                }}
                                                            >
                                                                <TriangleUp />
                                                                <TriangleUp
                                                                    style={{
                                                                        transform:
                                                                            'rotate(180deg)'
                                                                    }}
                                                                />
                                                            </Box>
                                                        )}
                                                    ></TableSortLabel>
                                                )}
                                        </Box>
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {visibleRows.map((row: EventZone) => {
                                return (
                                    <React.Fragment key={row.id}>
                                        <TableRow tabIndex={-1}>
                                            <StyledTableCell
                                                style={{
                                                    padding: `0 ${pxToRem(10)}`,
                                                    width: pxToRem(20)
                                                }}
                                                onClick={() =>
                                                    toggleRow(row.id)
                                                }
                                            >
                                                <IconButton>
                                                    <BottomChevronTable
                                                        style={{
                                                            transform:
                                                                expandedRows.includes(
                                                                    row.id
                                                                )
                                                                    ? 'rotate(180deg)'
                                                                    : 'initial'
                                                        }}
                                                    />
                                                </IconButton>
                                            </StyledTableCell>
                                            <StyledTableCell
                                                scope="row"
                                                padding="none"
                                            >
                                                {row.name}
                                            </StyledTableCell>
                                            <StyledTableCell padding="none">
                                                {row.description}
                                            </StyledTableCell>
                                            <StyledTableCell padding="none">
                                                {row.visibility_type}
                                            </StyledTableCell>
                                            <StyledTableCell
                                                padding="none"
                                                style={{
                                                    paddingLeft: pxToRem(25),
                                                    color: '#0092B8',
                                                    textDecoration: 'underline'
                                                }}
                                            >
                                                <ViewRooms
                                                    rooms={row.rooms_attached}
                                                    zone={row}
                                                />
                                            </StyledTableCell>
                                            <StyledTableCell padding="none">
                                                <TimeDisplay
                                                    time={row.start_time}
                                                />
                                            </StyledTableCell>
                                            <StyledTableCell padding="none">
                                                <TimeDisplay
                                                    time={row.end_time}
                                                />
                                            </StyledTableCell>
                                            <StyledTableCell
                                                align="right"
                                                sx={{
                                                    display: 'flex',
                                                    flexDirection: 'row',
                                                    justifyContent: 'flex-end'
                                                }}
                                            >
                                                <ActionButtons
                                                    zoneType={ZoneType.Event}
                                                    onSave={() => {}}
                                                    zone={row}
                                                />
                                            </StyledTableCell>
                                        </TableRow>
                                        <TableRow
                                            sx={{
                                                background:
                                                    'rgba(245, 245, 246, 0.50)'
                                            }}
                                        >
                                            <TableCell
                                                colSpan={8}
                                                padding="none"
                                            >
                                                <Collapse
                                                    in={expandedRows.includes(
                                                        row.id
                                                    )}
                                                    unmountOnExit
                                                >
                                                    <Box
                                                        sx={{
                                                            padding: `${pxToRem(
                                                                10
                                                            )} ${pxToRem(
                                                                20
                                                            )}  ${pxToRem(20)}`
                                                        }}
                                                    >
                                                        {row?.platforms && (
                                                            <PlatformPanel
                                                                setHasError={
                                                                    setHasError
                                                                }
                                                                platformSection={
                                                                    row.platforms
                                                                }
                                                                propertyId={
                                                                    propertyId
                                                                }
                                                                updateSubmitPayload={
                                                                    updateSubmitPayload
                                                                }
                                                                setShouldResetForm={
                                                                    setShouldResetForm
                                                                }
                                                                shouldResetForm={
                                                                    shouldResetForm
                                                                }
                                                                zoneId={row.id}
                                                                setShowSaveBar={
                                                                    setShowSaveBar
                                                                }
                                                                autoAffectedFieldIds={
                                                                    autoAffectedFieldIds
                                                                }
                                                                screenSaverSection={
                                                                    row.screen_saver
                                                                }
                                                                zoneToScreenSaverAssets={
                                                                    zoneToScreenSaverAssets
                                                                }
                                                                setZoneToScreenSaverAssets={
                                                                    setZoneToScreenSaverAssets
                                                                }
                                                                propertyLogoAssetId={
                                                                    propertyLogoAssetId
                                                                }
                                                            />
                                                        )}
                                                    </Box>
                                                </Collapse>
                                            </TableCell>
                                        </TableRow>
                                    </React.Fragment>
                                );
                            })}
                            {emptyRows > 0 && emptyRows < rowsPerPage && (
                                <TableRow
                                    style={{
                                        height: 63 * emptyRows
                                    }}
                                >
                                    <TableCell colSpan={8} />
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                    {!visibleRows.length && (
                        <Box
                            sx={{
                                width: '100%',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                minHeight: pxToRem(631)
                            }}
                        >
                            No Events Found
                        </Box>
                    )}
                </TableContainer>
            </Paper>
            <TablePagination
                sx={{ display: 'flex', justifyContent: 'center' }}
                rowsPerPageOptions={[]}
                component="div"
                count={filteredItems.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
            />
        </Box>
    );
};

export default EventsTable;
