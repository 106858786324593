import React from 'react';
import SearchIcon from '@mui/icons-material/Search';
import { InputAdornment } from '@mui/material';
import { pxToRem } from '../../utils/stylesUtils';
import {
    BaseTextFieldProps,
    className,
    CMPTextFieldContainer,
    StyledTextField,
    TextVariation,
    Theme
} from './cmpTextField';

export interface SearchTextFieldProps extends BaseTextFieldProps {
    placeholder: string;
    outlined?: boolean;
    variation?: TextVariation.SEARCH;
    disabled?: boolean;
    autoFocus?: boolean;
    onKeyDown?: (value: any) => void;
}

const SearchTextField = (props: SearchTextFieldProps) => {
    const {
        value,
        placeholder,
        onChange,
        sx,
        fullWidth = true,
        outlined = false,
        theme,
        disabled,
        backgroundTransparent,
        autoFocus,
        onKeyDown
    } = props;

    return (
        <CMPTextFieldContainer
            sx={{ width: fullWidth ? '100%' : 'auto', ...sx }}
        >
            <StyledTextField
                variant={outlined ? 'outlined' : 'standard'}
                className={className([
                    theme === Theme.DARK
                        ? 'cmp-text-field text-theme-dark'
                        : 'cmp-text-field text-theme-light',
                    disabled ? 'text-field-disabled' : 'text-field-enabled',
                    'no-dropdown-label',
                    'cmp-search-field'
                ])}
                sx={{
                    background: backgroundTransparent
                        ? 'rgba(0, 0, 16, 0.5)'
                        : theme === Theme.DARK && outlined
                        ? 'rgb(55, 55, 68)'
                        : 'inherit'
                }}
                fullWidth={fullWidth}
                value={value}
                onChange={(e) => onChange(e.target.value.trimStart() as string)}
                placeholder={placeholder}
                autoFocus={autoFocus}
                onKeyDown={onKeyDown}
                InputProps={{
                    startAdornment: (
                        <InputAdornment
                            position="start"
                            sx={outlined ? {} : { paddingLeft: pxToRem(16) }}
                        >
                            <SearchIcon
                                sx={{
                                    color:
                                        theme === Theme.DARK
                                            ? 'rgba(245, 245, 246, 0.8)'
                                            : 'rgba(0, 0, 0, 0.8)'
                                }}
                            />
                        </InputAdornment>
                    )
                }}
            />
        </CMPTextFieldContainer>
    );
};

export default SearchTextField;
