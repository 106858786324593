import Select from '@mui/material/Select';

import {
    FormControl,
    InputLabel,
    MenuItem,
    styled,
    SxProps,
    Box
} from '@mui/material';
import React, { useState } from 'react';
import { ReactComponent as ChevronArrow } from '../assets/svg/chevron.svg';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { pxToRem } from '../utils/stylesUtils';
import CMPErrorHelperText from './cmpErrorHelperText';
import { generateUUID } from '../utils/stringUtils';
import Tooltip from './tooltip';
import IconStylizer from './iconStylizer';

export enum DropdownVariation {
    DROPDOWN_DEFAULT = 'DROPDOWN_DEFAULT',
    DROPDOWN_NO_BORDER = 'DROPDOWN_NO_BORDER'
}

export interface Options {
    value: any;
    label: string;
}

export interface DefaultDropDownProps {
    required?: boolean;
    tooltipMessage?: string;
    label?: string;
    error?: boolean;
    options?: Options[];
    value?: any;
    helperText?: string;
    onChange: (option: any) => void;
    variation?: DropdownVariation;
    children?: React.ReactNode;
    placeholder?: string;
    dropDownOpen?: boolean;
    onOpen?: () => void;
    onClose?: () => void;
    disabled?: boolean;
    sx?: SxProps;
    dropdownStyles?: SxProps;
    id?: string;
    autoFocus?: boolean;
    disableAutoFocusItem?: boolean;
}

const DropdownIcon = (props: { isOpen: boolean; error?: boolean }) => {
    const { isOpen, error } = props;
    return (
        <Box>
            <IconStylizer
                color={error ? '#931621' : '#525261'}
                sx={{
                    position: 'absolute',
                    transform: 'translateY(-50%)',
                    pointerEvents: 'none',
                    right: 0,
                    fontSize: pxToRem(20),

                    paddingRight: pxToRem(8)
                }}
            >
                <ChevronArrow
                    style={{
                        height: pxToRem(10),
                        width: pxToRem(16),
                        transform: isOpen ? 'rotate(180deg)' : 'none'
                    }}
                />
            </IconStylizer>
        </Box>
    );
};

const DropDownLabel = styled(InputLabel)(() => ({
    display: 'flex',
    top: pxToRem(12),
    color: '#525261',
    fontSize: pxToRem(12),
    '&.MuiFormLabel-filled.Mui-focused': {
        color: '#525261'
    }
}));
const StyledSelect = styled(Select)(() => ({
    '& .MuiOutlinedInput-input': {
        fontWeight: 800,
        color: '#171725',
        fontSize: pxToRem(18)
    }
}));

const StyledDropDownContainer = styled(FormControl)(() => ({
    '&.drop-down-container .MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-formControl':
        {
            '&.Mui-focused': {
                color: '#525261'
            },
            '&.MuiInputLabel-shrink': {
                '&.dropdown-error': {
                    color: '#931621'
                },
                top: pxToRem(14),
                fontSize: pxToRem(16)
            },
            position: 'absolute',
            fontSize: pxToRem(20),
            top: -4,
            textTransform: 'capitalize'
        },
    '&.drop-down-container .MuiSelect-select.MuiSelect-outlined.MuiInputBase-input':
        {
            padding: `${pxToRem(22)} ${pxToRem(32)} 0 ${pxToRem(12)}`,
            minHeight: pxToRem(28)
        },
    '&.no-dropdown-label.drop-down-container': {
        '& .MuiSelect-select.MuiSelect-outlined.MuiInputBase-input': {
            paddingTop: pxToRem(14),
            paddingBottom: pxToRem(10)
        },
        '& .MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-formControl': {
            top: -2
        }
    }
}));

const selectVariationStyling = (variant: DropdownVariation) => {
    switch (variant) {
        case DropdownVariation.DROPDOWN_NO_BORDER:
            return {
                '&.Mui-focused': {
                    color: 'inherit'
                },
                boxShadow: 'none',
                ' & .MuiOutlinedInput-notchedOutline': { border: 0 },
                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                    border: 0
                }
            };

        default:
            return {
                ' & .MuiOutlinedInput-notchedOutline': { border: 0 },
                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                    border: 0
                },
                '&': {
                    border: '1px solid #525261',
                    borderRadius: pxToRem(7)
                },
                '&.Mui-disabled': {
                    '& .MuiButtonBase-root.MuiIconButton-root': {
                        opacity: '0.6'
                    },
                    borderColor: `rgba(82, 82, 97, 0.6)`
                },
                '&.Mui-error': { border: '1px solid #931621' }
            };
    }
};

const CMPDropDown = (props: DefaultDropDownProps) => {
    const [open, setOpen] = useState(false);
    const {
        label,
        error,
        tooltipMessage,
        required = false,
        options,
        value,
        helperText = required ? '*Required' : '',
        variation = DropdownVariation.DROPDOWN_DEFAULT,
        onChange,
        children,
        placeholder,
        dropDownOpen,
        onOpen,
        onClose,
        disabled = false,
        sx,
        id,
        dropdownStyles,
        autoFocus,
        disableAutoFocusItem
    } = props;

    const selectedValue = value === '' ? placeholder : value;
    const renderValue = (value: any) => {
        if (value === placeholder) {
            return <span style={{ color: '#525261' }}>{placeholder}</span>;
        }
        return value;
    };

    const labelId = generateUUID();
    return (
        <StyledDropDownContainer
            id={id}
            className={` ${
                label ? '' : 'no-dropdown-label'
            }  drop-down-container`}
            fullWidth
            sx={{ ...sx }}
        >
            <DropDownLabel
                id={`${labelId}-label`}
                shrink={true}
                className={error ? 'dropdown-error' : ''}
            >
                <Box sx={disabled ? { opacity: '0.6' } : {}}>{label}</Box>
                {tooltipMessage && (
                    <Tooltip message={tooltipMessage}>
                        <HelpOutlineIcon
                            style={{
                                height: pxToRem(20),
                                marginLeft: pxToRem(4),
                                width: pxToRem(20),
                                color: '#525261',
                                cursor: 'pointer'
                            }}
                        />
                    </Tooltip>
                )}
            </DropDownLabel>
            <StyledSelect
                sx={selectVariationStyling(variation)}
                required={required}
                labelId={`${labelId}-label`}
                name={labelId}
                label={label}
                value={selectedValue}
                error={error}
                open={dropDownOpen ?? open}
                onClose={() => (onClose ? onClose() : setOpen(false))}
                onOpen={() => (onOpen ? onOpen() : setOpen(true))}
                onChange={(e) => onChange(e.target.value as string)}
                IconComponent={() => (
                    <DropdownIcon isOpen={open} error={error} />
                )}
                disabled={disabled}
                renderValue={placeholder ? renderValue : undefined}
                MenuProps={{
                    autoFocus: autoFocus,
                    disableAutoFocusItem: disableAutoFocusItem,
                    PaperProps: {
                        sx: {
                            fontWeight: 500,
                            fontSize: pxToRem(16),
                            lineHeight: '21px',
                            '& .Mui-selected': { backgroundColor: '#E1E1E5' },
                            '& .Mui-selected.Mui-focusVisible.MuiMenuItem-root':
                                {
                                    backgroundColor: '#E1E1E5'
                                },
                            ...dropdownStyles
                        }
                    }
                }}
            >
                {options?.map((list) => {
                    return (
                        <MenuItem key={list.label} value={list.value}>
                            {list.label}
                        </MenuItem>
                    );
                })}
                {children}
            </StyledSelect>
            {error && <CMPErrorHelperText text={helperText} />}
        </StyledDropDownContainer>
    );
};

export default CMPDropDown;
