import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Paper } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Box } from '@mui/system';
import { FormikProvider, useFormik } from 'formik';
import _, { isArray, isEmpty } from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    // unstable_Blocker as BlockerInterface,
    // unstable_useBlocker as useBlocker,
    useLocation,
    useNavigate
} from 'react-router';
import NavigationPrompt from 'react-router-prompt';
import Button, { ButtonSize, ButtonVariation } from '../../components/button';
import CancelClickModal from '../../components/cancelClickModal';
import FullScreenCircularLoader from '../../components/fullScreenCircularLoader';
import { useSnackbar } from '../../components/snackbar';
import { useUrlParams } from '../../hooks/useParam';
import NoImageAvailable from '../../images/noImageAvailable.png';
import { default as CmpTexts, default as cmpTexts } from '../../locale/en';
import {
    selectAssetOperationLoadingState,
    selectAssets
} from '../../redux/assets/reducer';
import { LoadingStatus } from '../../redux/common/state';
import {
    createElement,
    fetchElement,
    fetchWidgetSkeleton,
    purgeElement,
    saveElement,
    setElementSatus
} from '../../redux/element/action';
import {
    ContentField,
    ContentFieldsKey,
    FieldType,
    WidgetSkeleton
} from '../../redux/element/model';
import {
    selectCreateElementResponse,
    selectCreateElementStatus,
    selectElementData,
    selectElementLoader,
    selectElementsError,
    selectImageLoader,
    selectPageBackgroundMessage,
    selectSaveElementResponse,
    selectSaveElementStatus
} from '../../redux/element/reducer';
import {
    fetchAllWidgetTypes,
    fetchElements,
    purgeElementLive
} from '../../redux/elementsList/action';
import { ElementCategory } from '../../redux/elementsList/model';
import {
    selectAllWidgetTypes,
    selectElementsLoadingStatus,
    selectRails,
    selectWidgets,
    selectWidgetTypeListLoadingStatus
} from '../../redux/elementsList/reducer';
import {
    addBreadCrumb,
    popBreadCrumb,
    saveNewElement
} from '../../redux/login/action';
import {
    selectBreadCrumb,
    selectNewCreatedElement
} from '../../redux/login/reducer';
import { ConfigureElementSchema } from '../../utils/configureElementUtil';
import { pxToRem } from '../../utils/stylesUtils';
import { shortDescription } from '../../utils/widgetDescription';
import PreviewButton from '../property/preview/previewButton';
import AddElement from './addElement';
import ElementGenerator from './elementContainers';
import TitleInput from './titleInput';
import { getPagePreviewDump } from '../property/preview/utils';
import { getPropertyDetails } from '../../redux/propertylist/action';

const SubContainer = styled('div')(() => ({
    paddingLeft: '3vw',
    paddingRight: '5vh'
}));

const CustomPaper = styled(Paper)(() => ({
    textAlign: 'start',
    paddingLeft: '2vw',
    paddingTop: '2vh',
    paddingBottom: '3vh',
    paddingRight: '5vh',
    padding: 25,
    marginBottom: pxToRem(64)
}));

const HeadingContainer = styled('div')(() => ({
    display: 'flex',
    flex: 1,
    justifyContent: 'space-between',
    alignItems: 'center'
}));

const ButtonContainer = styled('div')(() => ({
    flex: 1,
    display: 'flex',
    justifyContent: 'flex-end',
    paddingRight: 20,
    marginTop: '6vh'
}));

const PageDescription = styled('div')(() => ({
    display: 'flex',
    marginBottom: '2vh',
    marginTop: 7
}));

const InfoOutlinedIconStyle = styled('div')(() => ({
    marginTop: 1
}));

const DescriptionPageText = styled('div')(() => ({
    marginLeft: 10,
    fontSize: '1.04rem',
    fontWeight: 500
}));

function ConfigureElements() {
    const { showSnackbar } = useSnackbar();
    const dispatch = useDispatch();
    const location = useLocation();
    const { elementId, property } = useUrlParams();
    const navigate = useNavigate();

    // Selectors
    const elementValue = useSelector(selectElementData);
    const createElementStatus = useSelector(selectCreateElementStatus);
    const saveElementStatus = useSelector(selectSaveElementStatus);
    const elementStatus = useSelector(selectElementLoader);
    const elementError = useSelector(selectElementsError);
    const saveElementResponse = useSelector(selectSaveElementResponse);
    const imageLoader = useSelector(selectImageLoader);
    const breadCrumbs = useSelector(selectBreadCrumb);
    const newElementData = useSelector(selectNewCreatedElement);
    const createElementResponse = useSelector(selectCreateElementResponse);
    const widgetTypeListLoader = useSelector(selectWidgetTypeListLoadingStatus);
    const elementsLodingStatus = useSelector(selectElementsLoadingStatus);
    const uploadAssetLoader = useSelector(selectAssetOperationLoadingState);
    const assets = useSelector(selectAssets);

    const widgets = useSelector(selectWidgets);
    const rails = useSelector(selectRails);

    const allWidgetTypes = useSelector(selectAllWidgetTypes);
    const widgetTypeList = allWidgetTypes.filter(
        (wt) => wt.type === ElementCategory.WIDGET
    );
    const railTypeList = allWidgetTypes.filter(
        (wt) => wt.type === ElementCategory.RAIL
    );

    // states
    const [showModal, setShowModal] = useState(false);
    const [previewImage, setPreviewImage] = useState(NoImageAvailable);
    const [showAddElement, setShowAddElement] = useState(false);
    const [redirectParams, setRedirectParams] =
        useState<{ link: string; state: {} }>();
    const [isEdited, setIsEdited] = useState(false);
    const [inputBoxDisplay, setInputBoxDisplay] = useState(false);
    const [showCanvasButton, setShowCanvasButton] = useState(false);
    const unFocusRef = useRef<HTMLAnchorElement>(null);

    const pageBackgroundMessage =
        useSelector(selectPageBackgroundMessage) ??
        CmpTexts.configureElement.pageElementDescription;

    const routerState = location?.state;

    const formik = useFormik({
        initialValues: elementValue,
        validationSchema: ConfigureElementSchema,
        validateOnMount: false,
        onSubmit: () => {}
    });

    let redirectCancel: () => void | undefined,
        redirectConfirm: () => void | undefined;

    useEffect(() => {
        window.onbeforeunload = function () {
            console.log('in on before unload');
            // console.log(blocker);
            if (isEdited) {
                if (unFocusRef.current !== null) {
                    unFocusRef.current.click();
                }
                return true;
            }
            return null;
        };
        return () => {
            window.onbeforeunload = null;
        };
    }, [isEdited]);

    // solves auto-scrolling to page bottom
    useEffect(() => {
        dispatch(fetchAllWidgetTypes(property));
        dispatch(fetchElements(property));
        dispatch(purgeElementLive());
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        let url: string | undefined = location.pathname;
        url = url.split('/').pop();
        if (url === 'create') {
            setInputBoxDisplay(true);
            setShowCanvasButton(false);
            dispatch(
                fetchWidgetSkeleton(
                    routerState?.widget?.widgetType?.id,
                    property
                )
            );
        } else {
            setInputBoxDisplay(false);
            setShowCanvasButton(true);
            if (url !== undefined) {
                dispatch(fetchElement(url, property));
            }
        }

        return () => {
            dispatch(purgeElement());
            setIsEdited(false);
            setShowAddElement(false);
            setShowModal(false);
            setRedirectParams({ link: '', state: {} });
            const splitPathName = location.pathname.split('/');
            if (
                splitPathName.indexOf('elements') !==
                splitPathName.length - 2
            ) {
                dispatch(saveNewElement({ id: '', type: '' }));
            }
        };
    }, [location.pathname, routerState]);

    useEffect(() => {
        if (elementValue.id) {
            const elementData = { ...elementValue };
            if (
                newElementData?.id &&
                elementData?.id &&
                ((elementData?.type?.toLowerCase() === 'page' &&
                    newElementData?.type?.toLowerCase() === 'rail') ||
                    (elementData?.type?.toLowerCase() === 'rail' &&
                        newElementData?.type?.toLowerCase() === 'widget'))
            ) {
                // this is a patchy code written as doing it the write way would require bigger refactor,
                // this will be implemented property once we start working on canvas design
                // Here we are finding the index of ** MultiWidgetType content field **
                const mwtIndex = elementData.content_fields.findIndex(
                    (cf: ContentField) => cf.type === FieldType.MultiWidgetType
                );

                if (elementData.content_fields[mwtIndex].values) {
                    if (!isArray(elementData.content_fields[mwtIndex].values)) {
                        elementData.content_fields[mwtIndex].values = String(
                            elementData.content_fields[mwtIndex].values
                        ).split(',');
                    }
                    if (
                        (
                            elementData.content_fields[mwtIndex]
                                .values as string[]
                        ).indexOf(newElementData.id) === -1
                    ) {
                        (
                            elementData.content_fields[mwtIndex]
                                .values as string[]
                        ).push(newElementData.id);
                        setIsEdited(true);
                    }
                } else {
                    elementData.content_fields[mwtIndex].values = [];
                    if (
                        (
                            elementData.content_fields[mwtIndex]
                                .values as string[]
                        ).indexOf(newElementData.id) === -1
                    ) {
                        (
                            elementData.content_fields[mwtIndex]
                                .values as string[]
                        ).push(newElementData.id);
                        setIsEdited(true);
                    }
                }
                dispatch(saveNewElement({ id: '', type: '' }));
            } else if (
                elementData?.type &&
                elementData?.type?.toLowerCase() !==
                    newElementData?.type?.toLowerCase()
            ) {
                dispatch(saveNewElement({ id: '', type: '' }));
            }
            formik.setValues(elementData);
        }
    }, [elementValue]);

    useEffect(() => {
        if (createElementStatus === LoadingStatus.DONE) {
            const url = location.pathname.split('/');
            url.pop();
            url.push(createElementResponse.id);
            const baseUrl = url.join('/');
            setIsEdited(false);
            dispatch(getPropertyDetails(property));
            dispatch(
                saveNewElement({
                    id: createElementResponse.id,
                    type: routerState.widget.type
                })
            );
            dispatch(popBreadCrumb());
            dispatch(
                addBreadCrumb({
                    name:
                        createElementResponse.name ||
                        'New ' +
                            routerState.widget.type.toLowerCase() +
                            ' screen',
                    link: baseUrl,
                    state: routerState
                })
            );
            showSnackbar('Element Saved Successfully');
            if (redirectConfirm) redirectConfirm();
            else
                navigate(baseUrl, { replace: true, state: { ...routerState } });
        } else if (createElementStatus === LoadingStatus.FAILED) {
            if (elementError !== '') {
                showSnackbar(
                    elementError || cmpTexts.common.genericError,
                    'error'
                );
            }
            redirectCancel?.();
        }
    }, [createElementStatus]);

    useEffect(() => {
        if (saveElementStatus === LoadingStatus.DONE) {
            dispatch(getPropertyDetails(property));
            dispatch(
                saveNewElement({
                    id: saveElementResponse.id,
                    type: routerState?.widget?.type
                })
            );
            dispatch(
                addBreadCrumb({
                    name:
                        saveElementResponse.name ||
                        'New ' +
                            routerState.widget.type.toLowerCase() +
                            ' screen',
                    link: location.pathname,
                    state: routerState
                })
            );
            setIsEdited(false);
            showSnackbar('Element Saved Successfully');
            dispatch(setElementSatus('saveElementStatus'));
            redirectConfirm?.();
        } else if (saveElementStatus === LoadingStatus.FAILED) {
            console.error('element update failed - ', elementError);
            showSnackbar(elementError || cmpTexts.common.genericError, 'error');
            dispatch(setElementSatus('saveElementStatus'));
            redirectCancel?.();
        }
    }, [saveElementStatus]);

    useEffect(() => {
        if (routerState)
            dispatch(
                addBreadCrumb({
                    name:
                        elementValue.name ||
                        'New ' +
                            routerState.widget.type.toLowerCase() +
                            ' screen',
                    link: location.pathname,
                    state: routerState
                })
            );
    }, [elementValue, routerState]);

    useEffect(() => {
        if (!_.isEmpty(elementValue)) {
            if (elementValue.preview_url) {
                console.log('setting preview image url for element');
                setPreviewImage(elementValue.preview_url);
            } else {
                console.log('preview image url is not available for element');
                setPreviewImage(NoImageAvailable);
            }
        }
    }, [elementValue]);

    useEffect(() => {
        if (elementStatus === LoadingStatus.FAILED) {
            navigate(`/properties/${property}/elements`);
        }
    }, [elementStatus]);

    const getChildrenWidgetTypeListBasedOnCategory = (
        elementCategory: string
    ): WidgetSkeleton[] => {
        return elementCategory === ElementCategory.PAGE
            ? railTypeList
            : widgetTypeList;
    };

    const handleCancel = () => {
        if (breadCrumbs.length > 1) {
            if (routerState?.origin === 'element') {
                navigate(-1);
                redirectConfirm?.();
                return;
            }
            const url = breadCrumbs[breadCrumbs.length - 2].link;

            navigate(url, {
                replace: true,
                state: { ...breadCrumbs[breadCrumbs.length - 2].state }
            });
            redirectConfirm?.();
            return;
        }
        console.log('cancel clicked opening confirmation modal');
        setShowModal(!showModal);
    };

    function handleDelete() {
        if (redirectParams?.link) {
            navigate(String(redirectParams?.link), {
                state: {
                    ...redirectParams?.state
                }
            });
            redirectConfirm?.();
        } else {
            if (breadCrumbs.length > 1) {
                if (routerState?.origin === 'element') {
                    const url =
                        breadCrumbs[breadCrumbs.length - 2].link + '/elements';
                    navigate(url, {
                        replace: true,
                        state: {
                            ...breadCrumbs[breadCrumbs.length - 2].state
                        }
                    });
                    redirectConfirm?.();
                    return;
                }
                const url = breadCrumbs[breadCrumbs.length - 2].link;
                navigate(url, {
                    replace: true,
                    state: { ...breadCrumbs[breadCrumbs.length - 2].state }
                });
                setShowModal(false);
                redirectConfirm?.();
                return;
            } else {
                const newPath = location?.pathname?.split('/');
                newPath.pop();
                navigate(newPath.join('/'), {
                    replace: true,
                    state: { ...breadCrumbs[breadCrumbs.length - 2].state }
                });
                setShowModal(false);
                redirectConfirm?.();
                return;
            }
        }
    }

    const handleSubmit = () => {
        console.debug('updated form fields - ', formik.values);
        const headerCF = formik.values.content_fields.find(
            (cf) => cf.key === ContentFieldsKey.Header
        );

        if (!Object.keys(formik.errors).length) {
            if (elementId === 'create') {
                console.log('creating element');
                dispatch(createElement(formik.values, property));
            } else {
                console.log('updating element');
                dispatch(saveElement(formik.values, property));
            }
        } else {
            if (formik.errors.name) {
                showSnackbar(
                    cmpTexts.configureElement.errorMessage[0] +
                        formik.values.type.toLowerCase(),
                    'error'
                );
            } else if (!isEmpty(headerCF) && isEmpty(headerCF?.values)) {
                showSnackbar(
                    cmpTexts.configureElement.errorMessage[4],
                    'error'
                );
            } else {
                if (formik.values.type === ElementCategory.PAGE) {
                    showSnackbar(
                        cmpTexts.configureElement.errorMessage[1],
                        'error'
                    );
                } else if (formik.values.type === ElementCategory.RAIL) {
                    showSnackbar(
                        cmpTexts.configureElement.errorMessage[2],
                        'error'
                    );
                } else {
                    showSnackbar(
                        cmpTexts.configureElement.errorMessage[3],
                        'error'
                    );
                }
            }
            setShowModal(false);
            setShowAddElement(false);
            setRedirectParams({
                link: '',
                state: {}
            });
            redirectCancel?.();
        }
    };

    return (
        <>
            {(widgetTypeListLoader === LoadingStatus.INITIATED ||
                elementStatus === LoadingStatus.INITIATED ||
                createElementStatus === LoadingStatus.INITIATED ||
                saveElementStatus === LoadingStatus.INITIATED ||
                imageLoader === LoadingStatus.INITIATED ||
                uploadAssetLoader === LoadingStatus.INITIATED ||
                elementsLodingStatus === LoadingStatus.INITIATED) && (
                <FullScreenCircularLoader />
            )}
            {!_.isEmpty(elementValue.id) && (
                <SubContainer>
                    <HeadingContainer>
                        <TitleInput
                            inputBoxDisplay={inputBoxDisplay}
                            setInputBoxDisplay={setInputBoxDisplay}
                            setIsEdited={setIsEdited}
                            formik={formik}
                            enableEdit={elementValue.access_details?.Edit}
                        />
                        {elementValue.type.toLowerCase() === 'page' && (
                            <Box>
                                {showCanvasButton && (
                                    <Button
                                        buttonVariant={
                                            ButtonVariation.CONTAINED
                                        }
                                        buttonSize={ButtonSize.MEDIUM}
                                        sx={{ marginRight: pxToRem(16) }}
                                        onClick={() =>
                                            navigate(
                                                `${location.pathname}/canvas`
                                            )
                                        }
                                    >
                                        Canvas
                                    </Button>
                                )}

                                <PreviewButton
                                    pageId={elementValue.id}
                                    propertyId={property}
                                    pageConfigDump={getPagePreviewDump(
                                        formik.values,
                                        assets
                                    )}
                                />
                            </Box>
                        )}
                    </HeadingContainer>
                    <PageDescription>
                        <InfoOutlinedIconStyle>
                            <InfoOutlinedIcon />
                        </InfoOutlinedIconStyle>
                        <DescriptionPageText>
                            {routerState?.widget?.type === 'PAGE'
                                ? pageBackgroundMessage
                                : formik?.values?.short_description
                                ? formik?.values?.short_description
                                : shortDescription(formik?.values?.alias)}
                        </DescriptionPageText>
                    </PageDescription>
                    <CustomPaper elevation={4}>
                        <FormikProvider value={formik}>
                            <ElementGenerator
                                formik={formik}
                                setIsEdited={setIsEdited}
                                isEdited={isEdited}
                                setShowAddElement={setShowAddElement}
                                setShowModal={setShowModal}
                                setRedirectParams={setRedirectParams}
                                previewImage={previewImage}
                            />

                            <ButtonContainer>
                                <Button
                                    buttonVariant={ButtonVariation.TEXT}
                                    buttonSize={ButtonSize.LARGE}
                                    onClick={handleCancel}
                                >
                                    {CmpTexts.configureElement.cancel}
                                </Button>
                                <Button
                                    buttonVariant={ButtonVariation.CONTAINED}
                                    // type="submit"
                                    disabled={
                                        !(
                                            isEdited &&
                                            !Object.keys(formik.errors).length
                                        )
                                    }
                                    onClick={handleSubmit}
                                >
                                    {CmpTexts.configureElement.save}
                                </Button>
                            </ButtonContainer>
                            {showAddElement && formik && (
                                <AddElement
                                    widgetTypeList={getChildrenWidgetTypeListBasedOnCategory(
                                        routerState.widget.type.toUpperCase()
                                    )}
                                    setShowModal={setShowAddElement}
                                    addElementCategory={
                                        routerState.widget.type.toUpperCase() ===
                                        ElementCategory.PAGE
                                            ? ElementCategory.RAIL
                                            : ElementCategory.WIDGET
                                    }
                                    elements={
                                        routerState.widget.type.toUpperCase() ===
                                        ElementCategory.PAGE
                                            ? rails
                                            : widgets
                                    }
                                    formik={formik}
                                    setIsEdited={setIsEdited}
                                    isEdited={isEdited}
                                    setRedirectParams={setRedirectParams}
                                    setShowWarningModal={setShowModal}
                                />
                            )}
                            {showModal && (
                                <CancelClickModal
                                    handleDelete={handleDelete}
                                    handleSave={handleSubmit}
                                    showModal={showModal}
                                    setShowModal={() => {
                                        setShowModal(false);
                                        setShowAddElement(false);
                                        setRedirectParams({
                                            link: '',
                                            state: {}
                                        });
                                    }}
                                />
                            )}
                        </FormikProvider>
                    </CustomPaper>
                </SubContainer>
            )}
            <NavigationPrompt
                when={(callbackParams) => {
                    if (isEmpty(callbackParams)) {
                        return false;
                    }
                    const { currentLocation, nextLocation } = callbackParams;
                    const checkPathChange: boolean =
                        !nextLocation ||
                        nextLocation.pathname !== currentLocation.pathname;
                    const checkRouteStateChange =
                        currentLocation.pathname.split('/').pop() ===
                            'create' &&
                        nextLocation?.pathname.split('/').pop() === 'create' &&
                        currentLocation.state !== nextLocation?.state;
                    const checkCreateElementPathChange =
                        currentLocation.pathname.split('/').pop() ===
                            'create' &&
                        nextLocation?.pathname.split('/').pop() ===
                            createElementResponse.id;
                    return (
                        (checkPathChange || checkRouteStateChange) &&
                        isEdited &&
                        !checkCreateElementPathChange
                    );
                }}
            >
                {({
                    onConfirm,
                    onCancel
                }: {
                    onConfirm: any;
                    onCancel: any;
                }) => {
                    redirectCancel = onCancel;
                    redirectConfirm = onConfirm;
                    if (isEdited) {
                        return (
                            <CancelClickModal
                                handleDelete={handleDelete}
                                handleSave={handleSubmit}
                                showModal={true}
                                setShowModal={() => {
                                    setShowModal(false);
                                    setShowAddElement(false);
                                    setRedirectParams({
                                        link: '',
                                        state: {}
                                    });
                                    onCancel();
                                }}
                            />
                        );
                    }
                }}
            </NavigationPrompt>
        </>
    );
}

export default ConfigureElements;
