import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Button, { ButtonVariation } from '../../components/button';
import FullScreenCircularLoader from '../../components/fullScreenCircularLoader';
import CMPTexts from '../../locale/en';
import { forgotPassword, setForgotPassword } from '../../redux/login/action';
import { ForgotPasswordStatus } from '../../redux/login/model';
import { forgotPasswordValidationSchema } from '../../utils/loginUtils';
import { useSnackbar } from '../../components/snackbar';
import {
    selectError,
    selectForgotPasswordStatus,
    selectLoadingStatus
} from '../../redux/login/reducer';
import {
    ButtonContainer,
    FormDescription,
    FormHeading,
    FormWrapper,
    RedirectionLink,
    RequiredText
} from '../../components/cmpAuthWrapper';
import { pxToRem } from '../../utils/stylesUtils';
import CMPTextField from '../../components/textFields/cmpTextField';
import SuccessForm from './successForm';
import useQuery from '../../hooks/useQuery';

const ForgotPassword = () => {
    const { showSnackbar } = useSnackbar();

    const dispatch = useDispatch();
    const loadingStatus = useSelector(selectLoadingStatus);
    const forgotPasswordStatus = useSelector(selectForgotPasswordStatus);
    const navigate = useNavigate();
    const forgotPasswordError = useSelector(selectError);
    const query = useQuery();
    const email: string | null = query.get('email');

    const [showSuccess, setShowSuccess] = useState(false);

    useEffect(() => {
        dispatch(setForgotPassword());
    }, []);

    const formik = useFormik({
        initialValues: {
            email: ''
        },
        validationSchema: forgotPasswordValidationSchema,
        onSubmit: () => {}
    });

    useEffect(() => {
        formik.setFieldValue('email', email ?? '');
    }, []);

    useEffect(() => {
        dispatch(setForgotPassword()); //To prevent loader to execute just after landing on this page
        if (
            forgotPasswordStatus === ForgotPasswordStatus.FORGOT_PASSWORD_DONE
        ) {
            setShowSuccess(true);
        } else if (
            forgotPasswordStatus === ForgotPasswordStatus.FORGOT_PASSWORD_FAILED
        ) {
            showSnackbar(forgotPasswordError, 'error');
        }
    }, [forgotPasswordStatus]);

    const handleClick = () => {
        navigate(-1);
    };

    const handleSubmit = (e: React.SyntheticEvent) => {
        e.preventDefault();
        dispatch(forgotPassword(formik.values.email));
    };

    if (showSuccess) {
        return (
            <SuccessForm
                title={CMPTexts.forgotPassword.successCard.title}
                description={CMPTexts.forgotPassword.successCard.description}
                buttonText={CMPTexts.forgotPassword.successCard.button}
                onClick={() =>
                    navigate(`/changePassword?email=${formik.values.email}`)
                }
            />
        );
    }

    return (
        <>
            {loadingStatus && <FullScreenCircularLoader />}
            <FormWrapper onSubmit={handleSubmit}>
                <FormHeading>{CMPTexts.forgotPassword.title}</FormHeading>
                <FormDescription sx={{ marginBottom: pxToRem(16) }}>
                    {CMPTexts.forgotPassword.description}
                </FormDescription>
                <CMPTextField
                    required
                    id="email"
                    label={CMPTexts.login.textFieldEmail}
                    error={Boolean(formik.errors.email)}
                    onChange={(value) => formik.setFieldValue('email', value)}
                    value={formik.values.email}
                    helperText={formik.errors.email ? formik.errors.email : ''}
                />
                <RequiredText>{CMPTexts.common.requiredText}</RequiredText>
                <ButtonContainer>
                    <Button
                        type="submit"
                        buttonVariant={ButtonVariation.CONTAINED}
                        disabled={!(formik.isValid && formik.dirty)}
                    >
                        {CMPTexts.forgotPassword.button}
                    </Button>
                    <RedirectionLink onClick={handleClick}>
                        {CMPTexts.forgotPassword.returnToLogin}
                    </RedirectionLink>
                </ButtonContainer>
            </FormWrapper>
        </>
    );
};

export default ForgotPassword;
