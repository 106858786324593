import { Box, Grid, GridSize, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { isEmpty, isEqual, isNull } from 'lodash';
import React, { Fragment, useEffect } from 'react';
import { DisableableBox } from '../../../../components/disableableBox';
import TimePicker from '../../../../components/timePicker';
import WarningMessage from '../../../../components/warningMessage';
import {
    FileAsStringValue,
    MultiSelectBooleanValue,
    PropertyField,
    PropertyFieldValue,
    PropertyFieldValueType
} from '../../../../redux/propertyValue/model';
import {
    evaluateEditabilityCondition,
    evaluateMessagingCondition,
    evaluateRequiredCondition,
    evaluateValueCondition,
    evaluateVisibilityCondition
} from '../../../../utils/propertiesUtil';
import { pxToRem } from '../../../../utils/stylesUtils';
import PropertySectionTooltip from '../components/propertySectionTooltips';
import PropertySectionCheckBox from './propertySectionCheckBox';
import PropertySectionConsentCheckbox from './propertySectionConsentCheckbox';
import PropertySectionHorizontalOptionSelector from './propertySectionHorizontalOptionSelector';
import PropertySectionInfoText from './propertySectionInfoText';
import PropertySectionMultiSelectBoolean from './propertySectionMultiSelectBoolean';
import PropertySectionTextField from './propertySectionTextField';
import PropertySectionFileAsString from './propertySectionFileAsString';
import PropertySectionAccordionWithCheckbox from './propertySectionAccordionWithCheckbox';
import PropertySectionDropDown from './propertySectionDropDown';
import { TextVariation } from '../../../../components/textFields/cmpTextField';

interface PropertySectionComplexProps {
    section: PropertyField;
    allSectionsFields: PropertyField[];
    onFieldUpdate: (
        index: number,
        value: PropertyFieldValue,
        isManualUpdate: boolean
    ) => void;
    getPropertyFieldError: (index: number) => string;
    manuallyUpdatedFieldIds: string[];
    disabled?: boolean;
}

const HeaderContainer = styled(Box)(() => ({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
}));

const SectionHeader = styled(Typography)(() => ({
    color: '#171726',
    fontSize: pxToRem(18),
    fontWeight: 'bold',
    letterSpacing: 0,
    lineHeight: pxToRem(24)
}));

const DescriptionContainer = styled(Box)(() => ({
    display: 'flex',
    marginBottom: pxToRem(20)
}));

const DescriptionText = styled(Box)(() => ({
    fontSize: '1rem',
    fontWeight: 500
}));
interface ComplexSectionChildWithUIMetaData {
    key: string;
    widthFactor: number;
    skipWidthFactor: number;
    renderOnNextRow: boolean;
    canEdit: boolean;
    message?: string;
    index: number;
}
interface ComplexSectionElementFieldChild
    extends ComplexSectionChildWithUIMetaData {
    type: 'ELEMENT';
    field: PropertyField;
}

interface ComplexSectionMessageChild extends ComplexSectionChildWithUIMetaData {
    type: 'MESSAGE';
    message: string;
}

type ComplexSectionChild =
    | ComplexSectionElementFieldChild
    | ComplexSectionMessageChild;

const PropertySectionComplex = (props: PropertySectionComplexProps) => {
    const {
        section,
        allSectionsFields,
        onFieldUpdate,
        getPropertyFieldError,
        manuallyUpdatedFieldIds
    } = props;

    const sectionBooleanIndex =
        section.children?.findIndex(
            (child) =>
                child.value_type === PropertyFieldValueType.SectionBoolean
        ) ?? -1;

    let sectionBoolean: PropertyField | undefined = undefined;
    if (sectionBooleanIndex > -1) {
        sectionBoolean = section.children?.[sectionBooleanIndex];
    }

    const onFieldChange = (
        value: any,
        childIndex: number,
        isManualUpdate: boolean = true
    ) => {
        onFieldUpdate(childIndex, value, isManualUpdate);
    };

    useEffect(() => {
        section.children?.forEach((child, childIndex) => {
            const evaluatedValue = evaluateValueCondition(
                child,
                allSectionsFields
            );

            if (
                !isNull(evaluatedValue) &&
                !isEqual(evaluatedValue, child.value)
            ) {
                onFieldChange(evaluatedValue, childIndex, false);
            }
        });
    }, [section.children, allSectionsFields]);

    const propertyFieldValueTypeSelector = (
        child: PropertyField,
        childIndex: number,
        isRequired: boolean,
        canEdit: boolean
    ) => {
        const error = getPropertyFieldError(childIndex);

        switch (child.value_type) {
            case PropertyFieldValueType.Boolean:
                return (
                    <PropertySectionCheckBox
                        checked={Boolean(child.value)}
                        handleChange={(value) =>
                            onFieldChange(value, childIndex)
                        }
                        label={child.name}
                    />
                );
            case PropertyFieldValueType.DropDown:
                // eslint-disable-next-line no-case-declarations
                return (
                    <PropertySectionDropDown
                        required={false}
                        label={child.name}
                        tooltipMessage={child.configuration?.toolTip}
                        tooltipPresent={Boolean(child.configuration?.toolTip)}
                        error={Boolean(error)}
                        options={child.configuration?.options}
                        value={child.value}
                        onChange={(v) => onFieldChange(v, childIndex)}
                        helperText={error}
                    />
                );
            case PropertyFieldValueType.String:
                return (
                    <PropertySectionTextField
                        id={child.id}
                        maxLength={200}
                        value={child.value?.toString()}
                        tooltipMessage={child.configuration?.toolTip}
                        label={child.name}
                        handleFieldChange={(val) =>
                            onFieldChange(val, childIndex)
                        }
                        helperText={error}
                        error={Boolean(error)}
                        description={child.configuration?.helperText}
                        required={isRequired}
                        disabled={!canEdit}
                        variation={
                            child.configuration?.isSensitive
                                ? TextVariation.PASSWORD
                                : TextVariation.DEFAULT
                        }
                        removeAutoFill={child.configuration?.isSensitive}
                    />
                );
            case PropertyFieldValueType.Integer:
                return (
                    <PropertySectionTextField
                        id={child.id}
                        maxLength={200}
                        variation={TextVariation.NUMBER}
                        value={child.value?.toString()}
                        tooltipMessage={child.configuration?.toolTip}
                        label={child.name}
                        handleFieldChange={(val) =>
                            onFieldChange(
                                isEmpty(val) ? val : Number(val),
                                childIndex
                            )
                        }
                        helperText={error}
                        error={Boolean(error)}
                        disabled={!canEdit}
                        required={isRequired}
                        description={child.configuration?.helperText}
                    />
                );
            case PropertyFieldValueType.Time: {
                const time = child.value ?? '11:00:00';

                return (
                    <TimePicker
                        value={time as string}
                        onChange={(value) => onFieldChange(value, childIndex)}
                        required={isRequired}
                        ampm={child.configuration?.format === '12Hr'}
                    />
                );
            }
            case PropertyFieldValueType.HorizontalOptionSelector:
                return (
                    <PropertySectionHorizontalOptionSelector
                        description={child.description}
                        value={child.value}
                        options={child.configuration?.options ?? []}
                        onSelectOption={(value) =>
                            onFieldChange(value, childIndex)
                        }
                    />
                );
            case PropertyFieldValueType.MultiSelectBoolean: {
                const multiSelectBoolValue = child.value ?? {};
                return (
                    <PropertySectionMultiSelectBoolean
                        description={child.description}
                        value={multiSelectBoolValue as MultiSelectBooleanValue}
                        options={
                            child.configuration?.multiSelectBooleanOptions ?? []
                        }
                        onUpdateValue={(value) =>
                            onFieldChange(value, childIndex)
                        }
                    />
                );
            }
            case PropertyFieldValueType.InfoMessage: {
                const size =
                    child.configuration?.infoStyle === 'SMALL'
                        ? 'small'
                        : 'normal';
                return (
                    <PropertySectionInfoText
                        description={child.description}
                        size={size}
                    />
                );
            }

            case PropertyFieldValueType.ConsentCheckbox:
                return (
                    <PropertySectionConsentCheckbox
                        checked={Boolean(child.value)}
                        handleChange={(value) =>
                            onFieldChange(value, childIndex)
                        }
                        description={child.description}
                        helperText={error}
                    />
                );

            case PropertyFieldValueType.FileAsString:
                return (
                    <PropertySectionFileAsString
                        value={
                            child.value
                                ? (child.value as FileAsStringValue)
                                : null
                        }
                        allowedFileTypes={
                            child.configuration?.allowedFileTypes ?? []
                        }
                        fileUploadInfoMessage={
                            child.configuration?.fileUploadInfoMessage ?? ''
                        }
                        maxFileSize={child.configuration?.maxFileSize}
                        onUpdateValue={(value) =>
                            onFieldChange(value, childIndex)
                        }
                    />
                );

            case PropertyFieldValueType.AccordionDisplayMultiOptionSelector:
                return (
                    <PropertySectionAccordionWithCheckbox
                        value={(child.value as string[]) ?? []}
                        name={child.name}
                        options={child.configuration?.options ?? []}
                        handleChange={(value: string[]) =>
                            onFieldChange(value, childIndex)
                        }
                    />
                );

            default:
                return null;
        }
    };

    const getComplexSectionChildrenWithMeta = (): ComplexSectionChild[] => {
        const complexSectionChildren: ComplexSectionChild[] = [];
        let messageInsertionIndex = 0;
        let remainingWidthFactor = 0;

        section.children?.forEach((child, index) => {
            const canView = evaluateVisibilityCondition(
                child,
                allSectionsFields
            );

            if (
                !canView ||
                child.value_type === PropertyFieldValueType.SectionBoolean
            )
                return;

            const childWidthFactor = child.configuration?.widthFactor ?? 1;
            const renderOnNextRow =
                child.configuration?.startNextRow ??
                childWidthFactor > remainingWidthFactor;
            let skipWidthFactor = 0;

            // if starting on next row, reset the remaining width factor
            if (renderOnNextRow) {
                skipWidthFactor = remainingWidthFactor;
                remainingWidthFactor = 4;
                messageInsertionIndex = complexSectionChildren.length;
            }

            remainingWidthFactor = remainingWidthFactor - childWidthFactor;

            if (manuallyUpdatedFieldIds.includes(child.id)) {
                const warningMessage = evaluateMessagingCondition(
                    child,
                    allSectionsFields
                );

                if (warningMessage) {
                    const complexSectionMessageChild: ComplexSectionMessageChild =
                        {
                            key: `${child.id}_message`,
                            type: 'MESSAGE',
                            message: warningMessage,
                            widthFactor: 4,
                            skipWidthFactor: 0,
                            renderOnNextRow: true,
                            canEdit: false,
                            index: -1
                        };
                    complexSectionChildren.splice(
                        messageInsertionIndex,
                        0,
                        complexSectionMessageChild
                    );
                }
            }

            const [canEdit, message] = evaluateEditabilityCondition(
                child,
                allSectionsFields
            );

            const complexSectionElementChild: ComplexSectionElementFieldChild =
                {
                    key: child.id,
                    type: 'ELEMENT',
                    field: child,
                    widthFactor: childWidthFactor,
                    skipWidthFactor: skipWidthFactor,
                    renderOnNextRow: renderOnNextRow,
                    canEdit: canEdit,
                    message: message,
                    index: index
                };

            complexSectionChildren.push(complexSectionElementChild);
        });

        return complexSectionChildren;
    };

    const complexSectionChildWithMetaData = getComplexSectionChildrenWithMeta();

    return (
        <>
            <Box sx={{ marginBottom: pxToRem(12) }}>
                <HeaderContainer>
                    <SectionHeader>
                        <PropertySectionTooltip
                            tooltipMessage={section.configuration?.toolTip}
                            label={section.name}
                            tooltipPresent={Boolean(
                                section.configuration?.toolTip
                            )}
                        />
                    </SectionHeader>
                    {sectionBoolean && (
                        <DisableableBox
                            disabled={!sectionBoolean.access_details?.Edit}
                        >
                            <PropertySectionCheckBox
                                checked={Boolean(sectionBoolean?.value)}
                                handleChange={(value) =>
                                    onFieldChange(value, sectionBooleanIndex)
                                }
                                label={sectionBoolean?.name}
                            />
                        </DisableableBox>
                    )}
                </HeaderContainer>
                {section?.configuration?.showDescription && (
                    <DescriptionContainer>
                        <DescriptionText>
                            {section?.description}
                        </DescriptionText>
                    </DescriptionContainer>
                )}
            </Box>
            <Grid container>
                {complexSectionChildWithMetaData.map((child) => {
                    return (
                        <Fragment key={child.key}>
                            {child.renderOnNextRow && (
                                <Grid
                                    item
                                    md={(child.skipWidthFactor * 3) as GridSize}
                                />
                            )}
                            <Grid
                                sx={{
                                    padding: `${pxToRem(6)} ${pxToRem(
                                        20
                                    )} ${pxToRem(6)} 0`
                                }}
                                item
                                md={child.widthFactor * 3}
                            >
                                {child.type === 'MESSAGE' ? (
                                    <WarningMessage message={child.message} />
                                ) : (
                                    <DisableableBox
                                        disabled={!child.canEdit}
                                        disableReason={child.message}
                                    >
                                        {propertyFieldValueTypeSelector(
                                            child.field,
                                            child.index,
                                            evaluateRequiredCondition(
                                                child.field,
                                                allSectionsFields
                                            ),
                                            child.canEdit
                                        )}
                                    </DisableableBox>
                                )}
                            </Grid>
                        </Fragment>
                    );
                })}
            </Grid>
        </>
    );
};

export default PropertySectionComplex;
