import { Box, SxProps, Typography } from '@mui/material';
import { isEmpty } from 'lodash';
import React from 'react';
import { ReactComponent as InfoIcon } from '../assets/svg/info.svg';
import { pxToRem } from '../utils/stylesUtils';
import IconStylizer from './iconStylizer';
import { Theme } from './textFields/cmpTextField';

const CMPErrorHelperText = ({
    text,
    sx,
    theme = Theme.LIGHT
}: {
    text: string | undefined;
    sx?: SxProps;
    theme?: Theme;
}) => {
    if (isEmpty(text)) return null;

    return (
        <Box
            sx={{
                display: 'flex',
                alignItems: 'center',
                gap: pxToRem(5),
                marginTop: pxToRem(8),
                ...sx
            }}
        >
            <IconStylizer
                color={theme === Theme.LIGHT ? '#931621' : '#F4B4B9'}
                height={pxToRem(15)}
                width={pxToRem(16)}
                sx={{ display: 'flex' }}
            >
                <InfoIcon />
            </IconStylizer>

            <Typography
                sx={{
                    lineHeight: pxToRem(17),
                    fontSize: pxToRem(12),
                    color: theme === Theme.LIGHT ? '#931621' : '#F4B4B9'
                }}
            >
                {text}
            </Typography>
        </Box>
    );
};

export default CMPErrorHelperText;
