import React from 'react';
import CMPTextField, {
    TextVariation
} from '../../../../components/textFields/cmpTextField';
interface Props {
    id: string;
    maxLength: number;
    value?: string | number;
    label: string;
    handleFieldChange: (val: string) => void;
    error: boolean;
    helperText: string;
    maxNumber?: number;
    minNumber?: number;
    description?: string;
    required?: boolean;
    tooltipMessage?: string;
    disabled?: boolean;
    removeAutoFill?: boolean;
    variation:
        | TextVariation.DEFAULT
        | TextVariation.NUMBER
        | TextVariation.PASSWORD;
}

const PropertySectionTextField = (props: Props) => {
    const {
        id,
        maxLength,
        label,
        value,
        handleFieldChange,
        error,
        helperText,
        description,
        required,
        disabled,
        tooltipMessage,
        variation,
        removeAutoFill
    } = props;

    return (
        <CMPTextField
            required={required}
            id={id}
            maxLength={maxLength}
            label={label}
            variation={variation}
            value={value}
            tooltipMessage={tooltipMessage}
            onChange={handleFieldChange}
            error={error}
            helperText={helperText}
            disabled={disabled}
            fullWidth
            shrink
            removeAutoFill={removeAutoFill}
            supportingTexts={description}
        />
    );
};

export default PropertySectionTextField;
