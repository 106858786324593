import { Box } from '@mui/material';
import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, useNavigate } from 'react-router-dom';
import LoginBroadcastChannel from '../../auth/loginBroadcastChannel';
import Button, { ButtonVariation } from '../../components/button';
import FullScreenCircularLoader from '../../components/fullScreenCircularLoader';
import { useSnackbar } from '../../components/snackbar';
import CMPTexts from '../../locale/en';
import { setLoginStatus } from '../../redux/login/action';
import { LoginStatus } from '../../redux/login/model';
import {
    selectAuthStatus,
    selectError,
    selectLoginstatus
} from '../../redux/login/reducer';
import { loginValidationSchema } from '../../utils/loginUtils';
import {
    ButtonContainer,
    FormHeading,
    FormWrapper,
    RedirectionLink,
    RequiredText
} from '../../components/cmpAuthWrapper';
import { pxToRem } from '../../utils/stylesUtils';

import CMPTextField, {
    TextVariation
} from '../../components/textFields/cmpTextField';

function Login() {
    const { showSnackbar } = useSnackbar();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const loginStatus = useSelector(selectLoginstatus);
    const loginError = useSelector(selectError);
    const isAuthenticated = useSelector(selectAuthStatus);

    const [loginFailed, setLoginFailed] = useState(false);

    useEffect(() => {
        LoginBroadcastChannel.initLoginChannel();
    }, []);

    useEffect(() => {
        if (loginStatus === LoginStatus.FAILED) {
            dispatch(setLoginStatus(LoginStatus.NONE)); // To prevent error snackbar to popup again and again because state is persisted
            showSnackbar(loginError, 'error');

            setLoginFailed(true);
            formik.setFieldError('password', CMPTexts.login.loginError);

            console.error('Login Failed');
        } else if (isAuthenticated) {
            navigate('/properties', { replace: true });
            dispatch(setLoginStatus(LoginStatus.NONE));
        }
    }, [loginStatus, loginError]);

    const formik = useFormik({
        initialValues: {
            email: '',
            password: ''
        },
        validationSchema: loginValidationSchema,
        onReset: () => {},
        onSubmit: () => {}
    });

    function handleClick() {
        console.log('Redirecting to Forgot Password', formik.values.email);
        if (formik.values.email) {
            navigate(`/forgot_password?email=${formik.values.email}`);
        } else navigate(`/forgot_password`);
    }

    async function handleSubmit(e: React.SyntheticEvent) {
        e.preventDefault();
        try {
            console.log(`Logging In ${formik.values.email}...`);
            LoginBroadcastChannel.triggerLogin(formik.values);
        } catch (error) {
            console.log('Error logging in - ', error);
        }
    }

    const onFieldChange = (field: 'password' | 'email', value: string) => {
        formik.setFieldValue(field, value);
        setLoginFailed(false);
    };

    if (loginStatus === LoginStatus.SUCCESS && isAuthenticated)
        return <Navigate to="/properties" replace />;

    return (
        <>
            {loginStatus === LoginStatus.LOGGING_IN && (
                <FullScreenCircularLoader />
            )}
            <FormWrapper onSubmit={handleSubmit}>
                <FormHeading>{CMPTexts.login.paperHeading}</FormHeading>
                <CMPTextField
                    required
                    id="email"
                    label={CMPTexts.login.textFieldEmail}
                    onChange={(value) => onFieldChange('email', value)}
                    value={formik.values.email}
                    error={Boolean(formik.errors.email) || loginFailed}
                    helperText={formik.errors?.email ?? ''}
                />
                <CMPTextField
                    variation={TextVariation.PASSWORD}
                    required
                    id="password"
                    label={CMPTexts.login.textFieldPassword}
                    value={formik.values.password}
                    onChange={(value) => onFieldChange('password', value)}
                    error={Boolean(formik.errors.password)}
                    helperText={formik.errors?.password ?? ''}
                />

                <RequiredText>{CMPTexts.common.requiredText}</RequiredText>
                <Box sx={{ textAlign: 'right', marginTop: pxToRem(16) }}>
                    <RedirectionLink onClick={handleClick}>
                        {CMPTexts.login.resetPassword}
                    </RedirectionLink>
                </Box>
                <ButtonContainer>
                    <Button
                        type="submit"
                        buttonVariant={ButtonVariation.CONTAINED}
                        disabled={!(formik.isValid && formik.dirty)}
                    >
                        {CMPTexts.login.login}
                    </Button>
                </ButtonContainer>
            </FormWrapper>
        </>
    );
}
export default Login;
