import React from 'react';
import { Box } from '@mui/material';
import { ReactComponent as Checkmark } from '../../assets/svg/outlinedCheckmark.svg';
import Button, { ButtonVariation } from '../../components/button';
import {
    ButtonContainer,
    FormDescription,
    FormHeading,
    FormWrapper
} from '../../components/cmpAuthWrapper';
import { pxToRem } from '../../utils/stylesUtils';

interface SuccessFormProps {
    title: string;
    description: string;
    buttonText: string;
    onClick?: () => void;
}

const SuccessForm = ({
    title,
    description,
    buttonText,
    onClick
}: SuccessFormProps) => {
    return (
        <FormWrapper>
            <FormHeading>{title}</FormHeading>
            <Box sx={{ display: 'flex', gap: pxToRem(16) }}>
                <Checkmark style={{ flexShrink: 0 }} />
                <FormDescription>{description}</FormDescription>
            </Box>

            <ButtonContainer>
                <Button
                    buttonVariant={ButtonVariation.CONTAINED}
                    onClick={onClick}
                >
                    {buttonText}
                </Button>
            </ButtonContainer>
        </FormWrapper>
    );
};

export default SuccessForm;
