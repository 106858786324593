import React from 'react';
import { pxToRem } from '../utils/stylesUtils';

const CMP_LOGO_URL =
    'https://d35uwyuwpya61k.cloudfront.net/default/cmp_app_logo.png';

interface CMPLogoProps {
    size?: 'normal' | 'large';
}

const CMPLogo = (props: CMPLogoProps): React.ReactElement => {
    const { size = 'normal' } = props;

    return (
        <img
            src={CMP_LOGO_URL}
            style={
                size === 'normal'
                    ? { width: pxToRem(274) }
                    : { width: pxToRem(392) }
            }
            alt="CMP Logo"
        />
    );
};

export default CMPLogo;
