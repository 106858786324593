import { Grid, MenuItem, SelectChangeEvent } from '@mui/material';
import { styled } from '@mui/material/styles';
import React from 'react';
import { useNavigate } from 'react-router';
import Button, { ButtonSize, ButtonVariation } from '../../components/button';
import CMPDialog, {
    CMPDialogActions,
    CMPDialogContent,
    CMPDialogTitle
} from '../../components/cmpDialog';
import { useUrlParams } from '../../hooks/useParam';
import cmpTexts from '../../locale/en';
import { pxToRem } from '../../utils/stylesUtils';
import { AddWidgetModalProps } from './elementsInterface';
import CMPDropDown from '../../components/cmpDropDown';

const DescriptionText = styled('div')(() => ({
    fontSize: '0.98rem',
    fontWeight: 500
}));

const WidgetDescriptionText = styled('div')(() => ({
    fontSize: '0.87rem'
}));

const ContainerGrid = styled(Grid)(() => ({
    marginTop: pxToRem(16)
}));

const AddWidgetModal = (props: AddWidgetModalProps) => {
    const { setShowModal, selectedElementCategory, widgetTypeList } = props;

    const [widgetType, setWidgetType] = React.useState<any>();
    const navigate = useNavigate();
    const { property } = useUrlParams();

    const handleClose = () => {
        console.log('Add widget modal closed');
        setShowModal(false);
    };

    const handleSubmit = () => {
        navigate(`/properties/${property}/elements/create`, {
            state: {
                widget: { widgetType, type: selectedElementCategory },
                origin: 'element'
            }
        });
    };

    const handleChange = (value: SelectChangeEvent<unknown>) => {
        setWidgetType(value);
    };

    return (
        <div>
            <CMPDialog maxWidth="sm" onClose={handleClose}>
                <CMPDialogTitle sx={{ fontSize: pxToRem(24) }}>
                    {cmpTexts.addWidgetModal.modalHeading +
                        ' ' +
                        selectedElementCategory}
                </CMPDialogTitle>

                <CMPDialogContent>
                    <DescriptionText>
                        {`Choose a type of ${selectedElementCategory.toLowerCase()} to get started.`}
                    </DescriptionText>

                    <ContainerGrid container>
                        <Grid item xs={7}>
                            <CMPDropDown
                                value={widgetType}
                                label={selectedElementCategory}
                                onChange={handleChange}
                            >
                                {widgetTypeList.map((wt: any) => {
                                    return (
                                        <MenuItem key={wt.id} value={wt}>
                                            {wt.name}
                                        </MenuItem>
                                    );
                                })}
                            </CMPDropDown>
                        </Grid>
                    </ContainerGrid>

                    <WidgetDescriptionText>
                        {widgetType?.short_description}
                    </WidgetDescriptionText>
                </CMPDialogContent>

                <CMPDialogActions>
                    <Button
                        buttonVariant={ButtonVariation.OUTLINED_BLACK}
                        buttonSize={ButtonSize.LARGE}
                        onClick={handleClose}
                    >
                        {cmpTexts.addWidgetModal.cancelButton}
                    </Button>
                    <Button
                        buttonVariant={ButtonVariation.CONTAINED}
                        buttonSize={ButtonSize.LARGE}
                        onClick={() => handleSubmit()}
                        disabled={!widgetType}
                    >
                        {cmpTexts.addWidgetModal.submit}
                    </Button>
                </CMPDialogActions>
            </CMPDialog>
        </div>
    );
};

export default AddWidgetModal;
