import { useState, useCallback, useEffect } from 'react';
import debounce from 'lodash/debounce';

export function useDebouncedInput(
    initialValue: string = '',
    onChange: (value: string) => void,
    debounceTime: number = 200
) {
    const [debouncedValue, setDebouncedValue] = useState<string>(
        initialValue || ''
    );

    const debouncedHandler = useCallback(
        debounce((value: string) => {
            onChange(value);
        }, debounceTime),
        [onChange, debounceTime]
    );

    useEffect(() => {
        return () => {
            debouncedHandler.cancel();
        };
    }, [debouncedHandler]);

    useEffect(() => {
        setDebouncedValue(initialValue || '');
    }, [initialValue]);

    const debouncedOnChange = useCallback(
        (newValue: string) => {
            const valueToSet = newValue || '';
            setDebouncedValue(valueToSet);
            debouncedHandler(valueToSet);
        },
        [debouncedHandler]
    );

    return {
        debouncedValue,
        debouncedOnChange
    };
}
