import { Box, Grid, Link, styled, Typography } from '@mui/material';
import React from 'react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import DishBusinessLogo from '../assets/img/dishBusinessLogo.png';
import cmpTexts from '../locale/en';
import { pxToRem } from '../utils/stylesUtils';
import CMPLogo from './cmpLogo';

export const FormWrapper = styled('form')(() => ({
    padding: pxToRem(32),
    background: '#FFFFFF',
    borderRadius: pxToRem(15)
}));

export const FormHeading = styled(Typography)(() => ({
    fontSize: pxToRem(28),
    fontWeight: 800,
    lineHeight: pxToRem(32),
    letterSpacing: pxToRem(-0.20000000298023224),
    marginBottom: pxToRem(32)
}));

export const FormDescription = styled(Typography)(() => ({
    fontSize: pxToRem(18),
    lineHeight: pxToRem(24)
}));

export const RequiredText = styled(Typography)(() => ({
    fontSize: pxToRem(14),
    lineHeight: pxToRem(19),
    marginTop: pxToRem(8),
    color: '#525261'
}));

export const ButtonContainer = styled(Box)(() => ({
    display: 'flex',
    justifyContent: 'center',
    gap: pxToRem(16),
    alignItems: 'center',
    marginTop: pxToRem(32)
}));

export const RedirectionLink = styled(Link)(() => ({
    fontSize: pxToRem(18),
    lineHeight: pxToRem(18),
    textDecoration: 'underline',
    color: '#0092B8',
    '&:hover': { cursor: 'pointer' }
}));

const Container = styled(Box)(() => ({
    background: 'rgba(41, 41, 56, 1)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    width: '100vw',
    minHeight: '100vh'
}));

const ContentWrapper = styled(Box)(() => ({
    maxWidth: '90%',
    maxHeight: '90%',
    padding: '1%'
}));

const Heading = styled(Typography)(() => ({
    fontSize: pxToRem(44),
    fontWeight: 800,
    lineHeight: pxToRem(50),
    letterSpacing: pxToRem(-0.3799999952316284),
    marginBottom: '3%',
    color: 'white',
    '@media (max-width: 1550px)': {
        fontSize: pxToRem(30),
        lineHeight: pxToRem(34)
    },
    '@media (max-width: 1550px) and (max-height: 700px)': {
        fontSize: pxToRem(24),
        lineHeight: pxToRem(32),
        marginBottom: '1%'
    },
    '@media (max-width: 1330px)': {
        fontSize: pxToRem(28),
        lineHeight: pxToRem(32)
    },
    '@media (max-width: 1024px)': {
        fontSize: pxToRem(24),
        lineHeight: pxToRem(30)
    },
    '@media (max-width: 760px)': {
        fontSize: pxToRem(22),
        lineHeight: pxToRem(28)
    }
}));

const DishBusinessLogoContainer = styled(Box)(() => ({
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: '5%',
    '@media (max-width: 1550px) and (max-height: 700px)': {
        marginTop: '1%'
    }
}));

const CarouselImageContainer = styled(Box, {
    shouldForwardProp: (prop: string) => prop !== 'image'
})<{ image: string }>(({ image }) => ({
    border: `${pxToRem(12)} solid #000010`,
    background: `linear-gradient(135deg,
                                    rgba(0, 0, 0, 0.09) 0%,
                                    rgba(0, 0, 0, 0.13) 20%,
                                    rgba(0, 0, 0, 0.17) 40%,
                                    rgba(0, 0, 0, 0.20) 60%,
                                    rgba(0, 0, 0, 0.24) 80%,
                                    rgba(0, 0, 0, 0.33) 100%),
                                  url('${image}')`,
    aspectRatio: '16 / 9',
    backgroundSize: 'cover',
    backgroundPosition: 'center'
}));

const images = [
    'https://d35uwyuwpya61k.cloudfront.net/default/auth_carousel_img_1.png',
    'https://d35uwyuwpya61k.cloudfront.net/default/auth_carousel_img_2.png',
    'https://d35uwyuwpya61k.cloudfront.net/default/auth_carousel_img_3.png'
];

const CMPAuthWrapper = ({ children }: { children: React.ReactChild }) => {
    return (
        <Container>
            <ContentWrapper>
                <Box
                    sx={{
                        width: '30%',
                        marginBottom: '3%',
                        '@media (max-width: 1550px) and (max-height: 700px)': {
                            marginBottom: '1%'
                        }
                    }}
                >
                    <CMPLogo size="large" />
                </Box>
                <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                    <Box sx={{ maxWidth: '1208px' }}>
                        <Heading>{cmpTexts.common.heading}</Heading>
                        <Grid container spacing={11}>
                            <Grid item xs={7}>
                                <Box
                                    sx={{
                                        flex: 1,
                                        position: 'relative',
                                        aspectRatio: '16 / 9',
                                        '& .carousel': {
                                            '& li': { zIndex: '10 !important' }
                                        },
                                        '& .carousel.carousel-slider': {
                                            overflow: 'inherit',
                                            '.control-dots': { width: 'auto' },
                                            paddingBottom: pxToRem(28)
                                        },
                                        '& .carousel .thumbs-wrapper': {
                                            display: 'none'
                                        }
                                    }}
                                >
                                    <Carousel
                                        showStatus={false}
                                        showThumbs={false}
                                        autoFocus
                                        autoPlay
                                        showIndicators
                                        showArrows={false}
                                        infiniteLoop
                                        interval={3000}
                                        transitionTime={1000}
                                        animationHandler={'fade'}
                                    >
                                        {images.map((image) => (
                                            <CarouselImageContainer
                                                image={image}
                                                key={image}
                                            />
                                        ))}
                                    </Carousel>
                                </Box>
                            </Grid>
                            <Grid item xs={5}>
                                {children}
                            </Grid>
                        </Grid>
                        <DishBusinessLogoContainer>
                            <img
                                src={DishBusinessLogo}
                                style={{
                                    maxWidth: pxToRem(207),
                                    height: 'auto'
                                }}
                                alt="dish business"
                            />
                        </DishBusinessLogoContainer>
                    </Box>
                </Box>
            </ContentWrapper>
        </Container>
    );
};

export default CMPAuthWrapper;
