import React from 'react';
import {
    styled,
    TextField,
    Box,
    InputAdornment,
    InputAdornmentProps,
    Typography,
    TextFieldProps,
    SxProps
} from '@mui/material';
import { pxToRem } from '../../utils/stylesUtils';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import Tooltip from '../tooltip';
import IconStylizer from '../iconStylizer';
import { isEmpty } from 'lodash';
import DefaultTextField, { DefaultTextFieldProps } from './cmpDefaultTextField';
import PasswordTextFIeld, {
    PasswordTextFieldProps
} from './cmpPasswordTextField';
import SearchTextField, { SearchTextFieldProps } from './cmpSearchTextFiled';
import { useDebouncedInput } from '../../hooks/useDebounceInput';

export const className = (classes: string[]) => {
    return classes.join(' ');
};

export enum TextVariation {
    PASSWORD = 'PASSWORD',
    NUMBER = 'NUMBER',
    DEFAULT = 'DEFAULT',
    SEARCH = 'SEARCH'
}

export enum Theme {
    DARK = 'DARK',
    LIGHT = 'LIGHT'
}

export const CMPTextFieldContainer = styled(Box)(() => ({
    marginBottom: pxToRem(10)
}));

const TooltipIcon = styled(HelpOutlineIcon)(() => ({
    height: pxToRem(20),
    marginLeft: pxToRem(4),
    width: pxToRem(20),
    color: '#525261',
    cursor: 'pointer',
    position: 'absolute',
    right: pxToRem(0),
    top: -2
}));

// @ts-ignore
interface StyledTextFieldProps extends TextFieldProps {
    rowHeight?: number;
    rows?: number;
}

export const StyledTextField = styled(TextField, {
    shouldForwardProp: (prop) => prop !== 'height' && prop !== 'rowHeight'
})<StyledTextFieldProps>(({ rowHeight = 52, rows = 1 }) => ({
    '&.cmp-text-field': {
        height: `${rowHeight * rows}px`,
        '&.cmp-hide-text input:not([placeholder])': {
            '-webkit-text-security': 'disc',
            'text-security': 'disc'
        },
        '&.cmp-search-field': {
            '& .MuiInput-underline:before': {
                borderBottom: '2px solid #171725'
            },
            '& input': {
                '&::placeholder': {
                    color: '#171725',
                    opacity: 0.7,
                    fontWeight: 300
                }
            },
            '&.text-theme-dark': {
                '& .MuiInput-underline:before': {
                    borderBottom: '2px solid #F5F5F6'
                },
                '& input': {
                    color: '#F5F5F6',
                    '&::placeholder': {
                        color: '#F5F5F6',
                        opacity: 0.6
                    }
                }
            }
        },
        '&.no-dropdown-label .MuiInputBase-formControl.MuiOutlinedInput-root .MuiInputBase-input.MuiOutlinedInput-input':
            {
                padding: `${pxToRem(12)} ${pxToRem(14)} ${pxToRem(
                    12
                )} ${pxToRem(14)}`
            },
        '&.cmp-text-field-border-none .MuiInputBase-formControl.MuiOutlinedInput-root':
            {
                border: 'none'
            },
        '& .MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-outlined': {
            '&.MuiInputLabel-shrink': {
                top: pxToRem(15),
                fontSize: pxToRem(16),
                lineHeight: pxToRem(17),
                fontWeight: '500',
                color: '#525261'
            },
            '&.Mui-focused': {
                '&.MuiInputLabel-shrink': {
                    '&.MuiInputLabel-shrink .MuiFormLabel-asterisk': {
                        color: '#46237A'
                    },
                    color: '#46237A'
                }
            },
            '&.Mui-error': {
                '&.MuiInputLabel-shrink': {
                    '&.MuiInputLabel-shrink .MuiFormLabel-asterisk': {
                        color: '#931621'
                    },
                    color: '#931621'
                },
                '& .MuiFormLabel-asterisk': {
                    color: '#525261'
                },
                color: '#525261'
            },
            top: pxToRem(-4),
            paddingRight: pxToRem(25),
            display: 'flex'
        },
        '& .MuiInputBase-root.MuiOutlinedInput-root  textarea.MuiInputBase-input.MuiOutlinedInput-input.MuiInputBase-inputMultiline.MuiInputBase-inputAdornedEnd':
            {
                paddingTop: 0,
                paddingLeft: 0,
                lineHeight: pxToRem(18),
                fontSize: pxToRem(16),
                overflow: 'scroll',
                '&::-webkit-scrollbar': {
                    display: 'none'
                },
                marginTop: pxToRem(6),
                scrollbarWidth: 'none',
                maxHeight: pxToRem(rowHeight * rows)
                    ? pxToRem(rowHeight * rows - 40)
                    : 'auto'
            },
        '& .MuiInputBase-formControl.MuiOutlinedInput-root': {
            '& .MuiInputBase-input.MuiOutlinedInput-input': {
                fontSize: pxToRem(18),
                fontWeight: 500,
                lineHeight: pxToRem(21),
                padding: `${pxToRem(20)} ${pxToRem(14)} ${pxToRem(6)} ${pxToRem(
                    14
                )}`
            },
            '& fieldset': {
                border: 'none'
            },
            '&:hover fieldset': {
                border: 'none'
            },
            '&.Mui-focused': {
                '& fieldset': {
                    border: 'none'
                },
                border: '2px solid #46237A'
            },
            '&.Mui-error': {
                border: '2px solid #931621'
            },
            border: '1px solid #525261',
            height: pxToRem(rowHeight * rows),
            borderRadius: pxToRem(7),
            maxHeight: 'fit-Content'
        },
        borderRadius: pxToRem(7),
        maxHeight: 'fit-Content'
    },
    '&.cmp-text-field.text-theme-dark': {
        '& .MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-outlined': {
            '&.MuiInputLabel-shrink': {
                color: '#F5F5F6'
            },
            '& .MuiFormLabel-asterisk': {
                color: '#F5F5F6'
            },
            '&.Mui-focused': {
                '&.MuiInputLabel-shrink': { color: '#C9B4E9' },
                '&.MuiInputLabel-shrink .MuiFormLabel-asterisk': {
                    color: '#C9B4E9'
                }
            },
            '&.Mui-error': {
                '&.MuiInputLabel-shrink': { color: '#F4B4B9' },
                '&.MuiInputLabel-shrink .MuiFormLabel-asterisk': {
                    color: '#F4B4B9'
                },
                color: '#F5F5F6'
            },
            color: '#F5F5F6'
        },
        '& .MuiInputBase-formControl.MuiOutlinedInput-root': {
            '&.Mui-focused': {
                '& input::placeholder': {
                    color: '#C9B4E9',
                    opacity: 0.7
                },
                borderColor: '#C9B4E9'
            },
            '&.Mui-error': {
                borderColor: '#F4B4B9',
                '& input::placeholder': {
                    color: '#FFFFFF',
                    opacity: 0.7
                }
            },
            borderColor: '#FFFFFF',
            color: '#F5F5F6'
        }
    },
    '&.cmp-text-field.text-field-disabled': {
        '&.cmp-text-field.text-theme-dark': {
            '& .MuiInputBase-formControl.MuiOutlinedInput-root': {
                borderColor: 'rgba(255, 255, 255, 0.6)',
                color: 'rgba(255, 255, 255, 0.6)'
            },
            '& .MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-outlined': {
                '& .MuiFormLabel-asterisk': {
                    color: 'rgba(255, 255, 255, 0.6)'
                },
                color: 'rgba(255, 255, 255, 0.6)'
            }
        },
        '& .MuiInputBase-formControl.MuiOutlinedInput-root': {
            '.MuiInputBase-input.MuiOutlinedInput-input.Mui-disabled': {
                '-webkit-text-fill-color': 'inherit'
            },
            borderColor: 'rgb(82, 82, 97, 0.6)',
            color: 'rgba(0, 0, 0, 0.6)'
        },
        '& .MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-outlined': {
            '& .MuiFormLabel-asterisk': {
                color: 'rgba(0, 0, 0, 0.6)'
            },
            color: 'rgba(0, 0, 0, 0.6)'
        }
    }
}));

export interface StyledInputAdornmentProps extends InputAdornmentProps {
    darkTheme?: boolean;
    disabled?: boolean;
}

export interface BaseTextFieldProps {
    value?: any;
    onChange: (option: any) => void;
    disabled?: boolean;
    sx?: SxProps;
    fullWidth?: boolean;
    theme?: Theme;
    backgroundTransparent?: boolean;
    enableDebouce?: boolean;
    onFocus?: React.FocusEventHandler<HTMLInputElement>;
    onBlur?: React.FocusEventHandler<HTMLInputElement>;
}

export const StyledInputAdornment = styled(InputAdornment, {
    shouldForwardProp: (prop) =>
        prop !== 'darkTheme' && prop !== 'disabled' && prop !== 'sx'
})<StyledInputAdornmentProps>(({ darkTheme, disabled, sx }) => ({
    padding: pxToRem(8),
    '& p': {
        color: darkTheme ? '#FFFFFF' : '#000000',
        fontSize: pxToRem(12),
        fontWeight: 500,
        lineHeight: pxToRem(17),
        opacity: disabled ? 0.6 : 'none',
        ...sx
    }
}));

export const SupportingText = (props: {
    isDarkTheme: boolean;
    text: string | undefined;
}) => {
    if (isEmpty(props.text)) return null;
    return (
        <Typography
            sx={{
                lineHeight: pxToRem(17),
                fontSize: pxToRem(12),
                display: 'flex',
                justifyContent: 'flex-end',
                color: props.isDarkTheme ? '#F5F5F6' : '#525261',
                marginTop: pxToRem(3)
            }}
        >
            {props.text}
        </Typography>
    );
};

export const TooltipComponent = (props: {
    theme: Theme;
    tooltipMessage: string | undefined;
}) => {
    const { tooltipMessage, theme } = props;
    if (isEmpty(tooltipMessage)) return null;
    return (
        <Tooltip message={tooltipMessage as string}>
            <Box>
                <IconStylizer
                    color={theme === Theme.LIGHT ? '#525261' : '#F5F5F6'}
                    height={pxToRem(20)}
                    width={pxToRem(20)}
                    sx={{ display: 'flex' }}
                >
                    <TooltipIcon />
                </IconStylizer>
            </Box>
        </Tooltip>
    );
};

const CMPTextField = (
    props: DefaultTextFieldProps | PasswordTextFieldProps | SearchTextFieldProps
) => {
    const {
        variation = TextVariation.DEFAULT,
        enableDebouce = false,
        ...rest
    } = props;

    let updatedProps = rest;

    const { debouncedValue, debouncedOnChange } = useDebouncedInput(
        rest.value,
        rest.onChange
    );

    if (enableDebouce) {
        updatedProps = {
            ...rest,
            value: debouncedValue,
            onChange: debouncedOnChange
        };
    }

    if (isEmpty(updatedProps.value)) updatedProps.value = '';

    if (variation === TextVariation.PASSWORD) {
        return (
            <PasswordTextFIeld {...(updatedProps as PasswordTextFieldProps)} />
        );
    }
    if (variation === TextVariation.SEARCH) {
        return <SearchTextField {...(updatedProps as SearchTextFieldProps)} />;
    }

    return <DefaultTextField {...updatedProps} variation={variation} />;
};

export default CMPTextField;
