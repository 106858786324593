import React from 'react';
import CMPDropDown, {
    DropdownVariation
} from '../../../../components/cmpDropDown';
import { SxProps } from '@mui/material';

export interface PropertySectionDropdownProps {
    required?: boolean;
    tooltipMessage?: string;
    label?: string;
    error?: boolean;
    options?: any[];
    value: any;
    helperText?: string;
    onChange: (option: any) => void;
    variation?: DropdownVariation;
    children?: React.ReactNode;
    placeholder?: string;
    dropDownOpen?: boolean;
    onOpen?: () => void;
    onClose?: () => void;
    disabled?: boolean;
    sx?: SxProps;
    tooltipPresent?: boolean;
    id?: string;
}

const PropertySectionDropDown = (props: PropertySectionDropdownProps) => {
    const {
        required,
        tooltipMessage,
        label,
        error,
        options,
        value,
        helperText,
        onChange,
        variation,
        tooltipPresent,
        sx,
        id
    } = props;

    const modifiedOptions = (values: string[]) =>
        values.map((val) => ({ label: val, value: val }));
    return (
        <CMPDropDown
            required={required}
            label={label}
            error={error}
            options={options?.length ? modifiedOptions(options) : []}
            value={value}
            onChange={onChange}
            helperText={helperText}
            variation={variation}
            tooltipMessage={tooltipPresent ? tooltipMessage : undefined}
            sx={sx}
            id={id}
        />
    );
};

export default PropertySectionDropDown;
