import {
    Radio,
    RadioGroup,
    FormControlLabel,
    FormControl
} from '@mui/material';
import * as React from 'react';
import { ReactComponent as RadioButtonUncheckedIcon } from '../assets/svg/radioButtonUnchecked.svg';
import { ReactComponent as RadioButtonCheckedIcon } from '../assets/svg/radioButtonChecked.svg';
import { Options } from './cmpDropDown';

interface CMPRadioButtonGroupProps {
    options: Options[];
    onChange: (value: any) => void;
    orientation: Orientaton;
    value: any;
}

export enum Orientaton {
    HORIZONTAL = 'HORIZONTAL',
    VERTICAL = 'VERTICAL'
}

const CMPRadioButtonGroup = (props: CMPRadioButtonGroupProps) => {
    const { orientation, onChange, options, value } = props;

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        onChange(event.target.value);
    };

    return (
        <FormControl component="fieldset">
            <RadioGroup
                row={orientation === Orientaton.HORIZONTAL}
                value={value}
                onChange={handleChange}
            >
                {options.map((option) => (
                    <FormControlLabel
                        key={option.value}
                        value={option.value}
                        control={
                            <Radio
                                icon={<RadioButtonUncheckedIcon />}
                                checkedIcon={<RadioButtonCheckedIcon />}
                            />
                        }
                        label={option.label}
                    />
                ))}
            </RadioGroup>
        </FormControl>
    );
};

export default CMPRadioButtonGroup;
