/* eslint-disable react-hooks/exhaustive-deps */
import { Grid, InputAdornment, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useFormik } from 'formik';
import React, { useEffect } from 'react';
import * as Yup from 'yup';
import cmpTexts from '../../locale/en';
import { ContentField, HeaderVariants } from '../../redux/element/model';
import { pxToRem } from '../../utils/stylesUtils';
import CMPTextField from '../../components/textFields/cmpTextField';

interface HeaderGeneratorProps {
    variant: 'VARIANT_1' | 'VARIANT_2';
    item: ContentField;
    setIsEdited: (isEdited: boolean) => void;
    onHeaderChange: (headerObj: HeaderVariants) => void;
}

const Header = styled(Typography)(() => ({
    color: '#171726',
    fontSize: pxToRem(22),
    fontWeight: 'bold',
    letterSpacing: 0,
    lineHeight: pxToRem(24),
    marginBottom: pxToRem(4)
}));

const Description = styled(Typography)(() => ({
    color: '#171725',
    fontSize: pxToRem(14),
    letterSpacing: 0,
    lineHeight: pxToRem(16),
    marginBottom: pxToRem(28)
}));

export const StyledInputAdornment = styled(InputAdornment)(() => ({
    padding: 10
}));

function HeaderGenerator(props: HeaderGeneratorProps) {
    const { variant, item, setIsEdited, onHeaderChange } = props;

    const formik = useFormik({
        initialValues: item.values
            ? (item.values as HeaderVariants)
            : variant === 'VARIANT_1'
            ? { greeting: '', subHeadline: '', message: '' }
            : { greeting: '', message: '' },
        validationSchema:
            variant === 'VARIANT_1'
                ? Yup.object().shape({
                      greeting: Yup.string()
                          .trim()
                          .max(20, 'Should be less than 20'),
                      message: Yup.string()
                          .trim()
                          .max(50, 'Should be less than 50'),
                      subHeadline: Yup.string()
                          .trim()
                          .max(100, 'Should be less than 100')
                  })
                : Yup.object().shape({
                      greeting: Yup.string()
                          .trim()
                          .max(20, 'Should be less than 20'),
                      message: Yup.string()
                          .trim()
                          .max(50, 'Should be less than 50')
                  }),
        onSubmit: () => {}
    });

    useEffect(() => {
        onHeaderChange(formik.values);
        setIsEdited(formik.isValid && formik.dirty);
    }, [
        formik.values.greeting,
        formik.values.message,
        formik.values.subHeadline
    ]);

    return (
        <>
            <Header>{cmpTexts.headerGenerator.welcome}</Header>
            <Description>{cmpTexts.headerGenerator.desc}</Description>
            <Grid container rowSpacing={2}>
                <Grid container spacing={3} rowSpacing={1}>
                    <Grid item xs={4}>
                        <CMPTextField
                            label="Greeting"
                            name="greeting"
                            maxLength={20}
                            onChange={(val) =>
                                formik.setFieldValue('greeting', val)
                            }
                            placeholder="Welcome,"
                            value={formik.values.greeting}
                            error={Boolean(formik?.errors?.greeting)}
                            helperText={formik?.errors?.greeting}
                            displayCharacterLimit
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <CMPTextField
                            label="Guest Name"
                            name="Guest Name"
                            maxLength={30}
                            disabled
                            onChange={() => null}
                            value=""
                            supportingTexts="Guest Name is provided by PMS"
                            fullWidth
                            placeholder="<Guest First and Last Name>"
                            displayCharacterLimit
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <CMPTextField
                            label="Message"
                            name="message"
                            value={formik.values.message}
                            maxLength={50}
                            onChange={(val) =>
                                formik.setFieldValue('message', val)
                            }
                            error={Boolean(formik?.errors?.message)}
                            helperText={formik?.errors?.message}
                            placeholder="to the Ocean View Hotel Miami."
                            fullWidth
                            displayCharacterLimit
                        />
                    </Grid>
                </Grid>
                {variant === 'VARIANT_1' && (
                    <Grid item xs={12}>
                        <CMPTextField
                            label="Sub-Headline"
                            name="subHeadline"
                            maxLength={100}
                            error={Boolean(formik?.errors?.subHeadline)}
                            helperText={formik?.errors?.subHeadline}
                            placeholder="We hope that you enjoy your stay with us. Please let us know if you need any towels or blankets."
                            value={formik.values.subHeadline}
                            onChange={(val) =>
                                formik.setFieldValue('subHeadline', val)
                            }
                            fullWidth
                            displayCharacterLimit
                        />
                    </Grid>
                )}
                {variant === 'VARIANT_2' && (
                    <>
                        <Grid item xs={12}>
                            <Grid container spacing={3} rowSpacing={1}>
                                <Grid item xs={4}>
                                    <CMPTextField
                                        label="Check In"
                                        name="Check In"
                                        maxLength={20}
                                        disabled
                                        helperText="Check In is provided by PMS"
                                        placeholder="<Check In: MM/DD>"
                                        value=""
                                        onChange={() => null}
                                        fullWidth
                                        displayCharacterLimit
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <CMPTextField
                                        label="Check Out"
                                        name="Check Out"
                                        maxLength={20}
                                        disabled
                                        placeholder="<Check Out: MM/DD>"
                                        value=""
                                        helperText="Check Out is provided by PMS"
                                        onChange={() => null}
                                        fullWidth
                                        displayCharacterLimit
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container spacing={3} rowSpacing={1}>
                                <Grid item xs={4}>
                                    <CMPTextField
                                        label="Room Type"
                                        name="Room Type"
                                        maxLength={20}
                                        disabled
                                        placeholder="<Room Type>"
                                        value=""
                                        helperText="Room Type is provided by PMS"
                                        fullWidth
                                        displayCharacterLimit
                                        onChange={() => null}
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <CMPTextField
                                        label="Room Number"
                                        name="Room Number"
                                        maxLength={20}
                                        disabled
                                        placeholder="<Room Number>"
                                        value=""
                                        fullWidth
                                        helperText="Room Number is provided by PMS"
                                        displayCharacterLimit
                                        onChange={() => null}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </>
                )}
            </Grid>
        </>
    );
}

export default HeaderGenerator;
