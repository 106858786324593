import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import {
    Box,
    IconButton,
    LinearProgress,
    MenuItem,
    styled,
    Typography
} from '@mui/material';
import { head, isEmpty } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import DownloadAssetButton from '../../components/downloadAssetButton';
import cmpTexts from '../../locale/en';
import { Asset } from '../../redux/assets/model';
import { selectAssetsLoader } from '../../redux/assets/reducer';
import { LoadingStatus } from '../../redux/common/state';
import { MediaType } from '../../redux/element/model';
import { dataSizeConverter } from '../../utils/sizeConverter';
import { pxToRem } from '../../utils/stylesUtils';
import UploadAssetModal from '../assets/uploadAssetModal';
import VideoAssetThumbnail from '../assets/videoAssetThumbail';
import CMPDropDown from '../../components/cmpDropDown';

const ImageComponent = styled('img')(() => ({
    backgroundColor: '#eeeeee',
    padding: pxToRem(8),
    width: pxToRem(100),
    height: pxToRem(55)
}));

const ItemsContainer = styled('div')(() => ({
    display: 'grid',
    justifyContent: 'space-around',
    width: '100%',
    gridTemplateColumns: 'repeat(4, 1fr)'
}));

const AssetName = styled(Typography)(() => ({
    color: '#171725',
    fontSize: pxToRem(18),
    fontWeight: 600,
    letterSpacing: 0,
    lineHeight: pxToRem(44),
    maxWidth: 160,
    overflow: 'hidden',
    textOverflow: 'ellipsis'
}));

const SelectedAssetContainer = styled(Typography)(() => ({
    border: `${pxToRem(1)} dashed #292938`,
    padding: `${pxToRem(11)} ${pxToRem(15)} ${pxToRem(10)} ${pxToRem(17)}`,
    display: 'flex',
    justifyContent: 'space-between',
    cursor: 'pointer'
}));

const SelectedAssetText = styled(Typography)(() => ({
    fontSize: pxToRem(18),
    letterSpacing: 0,
    lineHeight: pxToRem(22)
}));

const SelectedAssetTextContainer = styled('div')(() => ({
    color: '#181818',
    paddingLeft: pxToRem(20)
}));

const AssetContentWrapper = styled('div')(() => ({
    display: 'flex'
}));

const IconsWrapper = styled('div')(() => ({
    display: 'flex',
    alignItems: 'center'
}));

const UploadAsset = styled(MenuItem)(() => ({
    color: '#F01446',
    fontSize: pxToRem(16),
    fontWeight: 'bold',
    letterSpacing: 0,
    lineHeight: pxToRem(17),
    textAlign: 'center'
}));

interface AssetDropDownProps {
    name: string;
    assets: Asset[];
    selectedAssetId: string;
    allowedFileTypes?: string[];
    assetUploadInfoMessage?: string;
    onAssetSelected: (assetid: string) => void;
    isRequired: boolean;
    maxFileSize?: number;
}

const AssetSelectionDropDown = (props: AssetDropDownProps) => {
    const [showUploadModal, toggleUploadModal] = useState(false);
    const {
        name,
        selectedAssetId,
        onAssetSelected,
        allowedFileTypes,
        assets,
        assetUploadInfoMessage,
        isRequired,
        maxFileSize
    } = props;
    const [dropDownOpen, setDropDownOpen] = useState(false);
    const [selectedAssetDetails, setSelectedAssetDetails] = useState<
        Asset | undefined
    >(undefined);
    const assetLoader = useSelector(selectAssetsLoader);
    const hasError = isRequired && isEmpty(selectedAssetId);
    useEffect(() => {
        if (!isEmpty(selectedAssetId)) {
            const selectedAsset = head(
                assets.filter((item) => item.id === selectedAssetId)
            );
            if (!isEmpty(selectedAsset)) setSelectedAssetDetails(selectedAsset);
        } else {
            setSelectedAssetDetails(undefined);
        }
    }, [selectedAssetId, assets]);

    const isVideo =
        selectedAssetDetails?.type.toLowerCase() ===
        MediaType.Video.toLowerCase();

    return (
        <>
            {selectedAssetDetails ? (
                <SelectedAssetContainer
                    onClick={() => {
                        onAssetSelected('');
                        setDropDownOpen(true);
                    }}
                >
                    <AssetContentWrapper>
                        {isVideo ? (
                            <VideoAssetThumbnail
                                src={selectedAssetDetails.url}
                            />
                        ) : (
                            <ImageComponent
                                src={selectedAssetDetails.url}
                                alt={name}
                            />
                        )}
                        <SelectedAssetTextContainer>
                            <SelectedAssetText>
                                {`${
                                    selectedAssetDetails.name
                                }.${selectedAssetDetails.file_extension.toLowerCase()}`}
                            </SelectedAssetText>

                            <SelectedAssetText>
                                {dataSizeConverter(selectedAssetDetails.size)}
                            </SelectedAssetText>
                        </SelectedAssetTextContainer>
                    </AssetContentWrapper>
                    <IconsWrapper>
                        <DownloadAssetButton
                            assetUrl={selectedAssetDetails.url}
                            fileName={selectedAssetDetails.name}
                            extension={selectedAssetDetails.file_extension}
                        />

                        <IconButton
                            onClick={(e) => {
                                e.stopPropagation();
                                onAssetSelected('');
                            }}
                        >
                            <DeleteIcon sx={{ color: 'black' }} />
                        </IconButton>
                    </IconsWrapper>
                </SelectedAssetContainer>
            ) : (
                <CMPDropDown
                    label={`Select ${name}`}
                    value={selectedAssetId}
                    dropdownStyles={{ maxHeight: '20rem' }}
                    dropDownOpen={dropDownOpen}
                    onOpen={() => setDropDownOpen(true)}
                    onClose={() => setDropDownOpen(false)}
                    onChange={onAssetSelected}
                    required={isRequired}
                    error={hasError}
                >
                    {assetLoader === LoadingStatus.INITIATED && (
                        <MenuItem>
                            <Box sx={{ width: '100%' }}>
                                <LinearProgress />
                            </Box>
                        </MenuItem>
                    )}
                    <UploadAsset
                        onClick={() => {
                            toggleUploadModal(true);
                        }}
                        value={selectedAssetId}
                    >
                        <AddIcon sx={{ marginRight: pxToRem(11) }} />
                        {cmpTexts.configureElement.uploadAsset}
                    </UploadAsset>
                    {assets.map((asset: Asset) => {
                        const {
                            id,
                            name,
                            url,
                            file_extension: fileType,
                            size
                        } = asset;
                        const isVideoAsset =
                            asset.type.toLowerCase() ===
                            MediaType.Video.toLowerCase();
                        return (
                            <MenuItem key={id} value={id}>
                                <ItemsContainer>
                                    {isVideoAsset ? (
                                        <VideoAssetThumbnail src={url} />
                                    ) : (
                                        <ImageComponent src={url} alt={name} />
                                    )}
                                    <AssetName>{name}</AssetName>
                                    <Typography
                                        sx={{ lineHeight: pxToRem(44) }}
                                    >
                                        {fileType}
                                    </Typography>
                                    <Typography
                                        sx={{ lineHeight: pxToRem(44) }}
                                    >
                                        {dataSizeConverter(size)}
                                    </Typography>
                                </ItemsContainer>
                            </MenuItem>
                        );
                    })}
                </CMPDropDown>
            )}

            {showUploadModal && (
                <UploadAssetModal
                    allowedFileTypes={allowedFileTypes}
                    assetUploadInfoMessage={assetUploadInfoMessage}
                    setIsModalOpen={toggleUploadModal}
                    onAssetUploaded={(asset: Asset) => {
                        onAssetSelected(asset.id);
                    }}
                    maxFileSize={maxFileSize}
                />
            )}
        </>
    );
};

export default AssetSelectionDropDown;
