import { Box, Divider, InputLabel } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useFormik } from 'formik';
import { get } from 'lodash';
import React, { useEffect } from 'react';
import StyledPaper from '../../../components/styledPaper';
import cmpTexts from '../../../locale/en';
import { PropertyField } from '../../../redux/propertyValue/model';
import { propertyFieldsValidationSchema } from '../../../utils/propertiesUtil';
import { pxToRem } from '../../../utils/stylesUtils';
import PropertyAssetSection from './propertyAssetsSection';
import PropertyThemeSection from './propertyThemeSection';
import { PropertyFieldSectionTitle } from './styles';

export const LightThemeColorFieldset = styled('fieldset')(() => ({
    borderStyle: 'solid',
    borderWidth: 1,
    padding: 1.8,
    width: '100%',
    borderColor: '#000000',
    borderRadius: 4,
    paddingRight: 15
}));

export const LightThemeInputLabel = styled(InputLabel)(() => ({
    fontSize: 10
}));

export const LightThemeDiv = styled('div')(() => ({
    display: 'flex'
}));

const BoxContainer = styled(Box)(() => ({
    display: 'flex',
    flexWrap: 'wrap',
    '& > :not(style)': {
        m: 1,
        width: '100%'
    }
}));

export const DividerLine = styled(Divider)(() => ({
    marginTop: '3vh',
    marginBottom: '3vh'
}));

interface Props {
    setShowSaveBar: (showSaveBar: boolean) => void;
    setHasError: (setHasError: boolean) => void;
    themesAndAssets: PropertyField[];
    updateSubmitPayload: (
        pfId: string,
        value: any,
        isManualUpdate: boolean
    ) => void;
    setShouldResetForm: (shouldResetForm: boolean) => void;
    shouldResetForm: boolean;
    setPropertyLogoAssetId: (value: string) => void;
}

function PropertyThemeAndAssetsSection(props: Props) {
    const {
        setShowSaveBar,
        setHasError,
        themesAndAssets,
        updateSubmitPayload,
        shouldResetForm,
        setShouldResetForm,
        setPropertyLogoAssetId
    } = props;

    const formik = useFormik<PropertyField[]>({
        initialValues: themesAndAssets,
        enableReinitialize: true,
        validationSchema: propertyFieldsValidationSchema,
        onSubmit() {}
    });

    useEffect(() => {
        if (shouldResetForm) {
            formik.resetForm();
            setShouldResetForm(!shouldResetForm);
        }
    }, [shouldResetForm]);

    useEffect(() => {
        setHasError(Object.keys(formik.errors).length > 0);
    }, [formik.errors]);

    const { values } = formik;

    const onFieldUpdate = (childIndex: number, index: number, value: any) => {
        formik.setFieldValue(`[${index}].children[${childIndex}].value`, value);
        const propertyFieldId = get(
            formik.values,
            `[${index}].children[${childIndex}].id`,
            ''
        );
        updateSubmitPayload(propertyFieldId, value, true);
        setShowSaveBar(true);
    };

    const getPropertyFieldError = (
        sectionIndex: number,
        childIndex: number
    ): string => {
        const errorMessage = get(
            formik.errors,
            `[${sectionIndex}].children[${childIndex}].value`,
            ''
        );
        return errorMessage;
    };

    return (
        <BoxContainer>
            <PropertyFieldSectionTitle gutterBottom>
                {cmpTexts.PropertyGroupTexts.propertyBrandKit}
            </PropertyFieldSectionTitle>
            <StyledPaper>
                {values?.map((pf: PropertyField, index: number) => {
                    const viewPropertyField = pf.access_details?.View;
                    if (!viewPropertyField) return null;

                    switch (pf.key) {
                        case 'theme': {
                            return (
                                <Box sx={{ margin: pxToRem(40) }}>
                                    <PropertyThemeSection
                                        theme={pf}
                                        getPropertyFieldError={(cIndex) =>
                                            getPropertyFieldError(index, cIndex)
                                        }
                                        onFieldUpdate={(
                                            childIndex: number,
                                            value: any
                                        ) =>
                                            onFieldUpdate(
                                                childIndex,
                                                index,
                                                value
                                            )
                                        }
                                    />
                                </Box>
                            );
                        }

                        case 'assets': {
                            return (
                                <Box sx={{ margin: pxToRem(40) }}>
                                    <PropertyAssetSection
                                        mediaConfigs={pf.configuration}
                                        asset={pf}
                                        onFieldUpdate={(
                                            childIndex: number,
                                            value: any
                                        ) => {
                                            setPropertyLogoAssetId(value);
                                            onFieldUpdate(
                                                childIndex,
                                                index,
                                                value
                                            );
                                        }}
                                    />
                                </Box>
                            );
                        }

                        default:
                            return null;
                    }
                })}
            </StyledPaper>
        </BoxContainer>
    );
}

export default PropertyThemeAndAssetsSection;
