import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import FullScreenCircularLoader from '../components/fullScreenCircularLoader';
// import CMPBackground from '../components/cmpBackground';
import NavDrawer from '../components/navDrawer';
import { LoadingStatus } from '../redux/common/state';
import {
    selectAuthStatus,
    selectUserEmail,
    selectUserName,
    selectUserRole
} from '../redux/login/reducer';
import { selectMaintenanceStatus } from '../redux/maintenance/reducer';
import { coreRootState } from '../redux/store.types';
import { getOperations } from '../redux/userOperations/action';
import {
    selectOperationLoaderStatus,
    selectOperationsInitialised
} from '../redux/userOperations/reducer';
import { UserRole } from '../redux/users/model';
import UnderMaintenance from '../screens/maintenance/underMaintenance';
import CommentsModal from '../screens/property/commentsModal';
import CMPAuthWrapper from '../components/cmpAuthWrapper';
interface PrivateRouteProps {
    isAllowed: (state: coreRootState) => boolean | undefined;
    children: React.ReactNode;
    hideNavBar?: boolean;
}

export const PrivateRoute = (props: PrivateRouteProps): React.ReactElement => {
    const { isAllowed, hideNavBar = false } = props;
    const dispatch = useDispatch();
    const isAuthenticated = useSelector(selectAuthStatus);
    const hasAccess = useSelector(isAllowed);
    const userName = useSelector(selectUserName);
    const userEmail = useSelector(selectUserEmail);
    const userRole = useSelector(selectUserRole);
    const isUnderMaintenance = useSelector(selectMaintenanceStatus);
    const isSuperAdmin = userRole === UserRole.SuperAdmin;
    const navigate = useNavigate();
    const operationsLoadingStatus = useSelector(selectOperationLoaderStatus);
    const isOperationsInitialized = useSelector(selectOperationsInitialised);

    useEffect(() => {
        if (isAuthenticated) dispatch(getOperations(userRole));
        else navigate('/login');
    }, []);

    useEffect(() => {
        if (isAuthenticated && window.newrelic) {
            window.newrelic.setCustomAttribute('User Email', userEmail, true);
            window.newrelic.setCustomAttribute('User Name', userName, true);
            window.newrelic.setCustomAttribute('User Role', userRole, true);
        }
    }, [isAuthenticated]);

    const pathName = useLocation().pathname;

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathName]);

    if (
        operationsLoadingStatus === LoadingStatus.INITIATED &&
        !isOperationsInitialized
    ) {
        return (
            <CMPAuthWrapper>
                <FullScreenCircularLoader />
            </CMPAuthWrapper>
        );
    }

    if (isAuthenticated && isUnderMaintenance && !isSuperAdmin)
        return (
            <NavDrawer>
                <UnderMaintenance />
            </NavDrawer>
        );

    if (isAuthenticated) {
        return isOperationsInitialized && !hasAccess ? (
            <Navigate to="/accessDenied" replace />
        ) : hideNavBar ? (
            <>
                {props.children}
                <CommentsModal />
            </>
        ) : (
            <>
                <NavDrawer>{props.children}</NavDrawer>
                <CommentsModal />
            </>
        );
    } else {
        return <Navigate to="/login" replace />;
    }
};
