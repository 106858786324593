import React from 'react';
import CMPDialog, {
    CMPDialogActions,
    CMPDialogContent,
    CMPDialogTitle
} from '../../components/cmpDialog';
import * as Yup from 'yup';
import { pxToRem } from '../../utils/stylesUtils';
import { Grid, Typography } from '@mui/material';
import Button, { ButtonSize, ButtonVariation } from '../../components/button';
import { FormikProps, useFormik } from 'formik';
import { useUrlParams } from '../../hooks/useParam';
import { RequiredText } from '../property/zones/manageZones';
import CanvasModalEditor from '../canvas/canvasModalEditor';
import { useDispatch, useSelector } from 'react-redux';
import { duplicateElement } from '../../redux/elementsList/action';
import { selectElements } from '../../redux/elementsList/reducer';
import { ElementCategory } from '../../redux/elementsList/model';
import generateUniqueName from '../../utils/generateUniqueName';
import cmpTexts from '../../locale/en';
import CMPTextField, { Theme } from '../../components/textFields/cmpTextField';

interface DuplicateElementsProps {
    setShowModal: (show: boolean) => void;
    elementName: string;
    darkMode?: boolean;
    elementId: string;
    elementType: ElementCategory;
}

interface DialogContentProps {
    darkMode?: boolean;
    elementType: ElementCategory;
    handleSubmit: (e: React.SyntheticEvent) => void;
    elementTypeDisplayName: string;
    formik: FormikProps<{
        elementName: string;
        filteredElementNames: string[];
    }>;
    handleClose: () => void;
}

const DialogContent = (props: DialogContentProps) => {
    const {
        darkMode = false,
        handleSubmit,
        elementTypeDisplayName,
        formik,
        handleClose,
        elementType
    } = props;
    const { duplicateElementMessage } = cmpTexts;
    return (
        <>
            <CMPDialogTitle sx={{ fontWeight: 800 }}>
                {`Duplicate ${elementTypeDisplayName}`}
            </CMPDialogTitle>
            <Typography sx={{ paddingBottom: pxToRem(10) }}>
                {elementType === ElementCategory.PAGE
                    ? duplicateElementMessage.pageDescription
                    : elementType === ElementCategory.RAIL
                    ? duplicateElementMessage.railDescription
                    : duplicateElementMessage.toolDescription}
            </Typography>
            <CMPDialogContent>
                <Grid
                    container
                    spacing={1}
                    rowSpacing={2}
                    sx={{ marginTop: pxToRem(5) }}
                >
                    <Grid item xs={12}>
                        <CMPTextField
                            id="elementName"
                            onChange={(val) =>
                                formik.setFieldValue('elementName', val)
                            }
                            theme={darkMode ? Theme.DARK : Theme.LIGHT}
                            value={formik.values.elementName}
                            sx={{
                                width: '98%'
                            }}
                            error={Boolean(formik.errors.elementName)}
                            label={`${elementTypeDisplayName} Name`}
                            required
                            helperText={formik.errors.elementName}
                        />
                    </Grid>
                </Grid>
                <RequiredText>*Required Field</RequiredText>
            </CMPDialogContent>
            <CMPDialogActions
                sx={{
                    display: 'flex',
                    justifyContent: 'center'
                }}
            >
                <Button
                    buttonVariant={
                        darkMode
                            ? ButtonVariation.OUTLINED
                            : ButtonVariation.OUTLINED_BLACK
                    }
                    buttonSize={ButtonSize.LARGE}
                    onClick={handleClose}
                >
                    Cancel
                </Button>
                <Button
                    buttonVariant={ButtonVariation.CONTAINED}
                    buttonSize={ButtonSize.LARGE}
                    disabled={!formik.isValid}
                    onClick={handleSubmit}
                >
                    Save
                </Button>
            </CMPDialogActions>
        </>
    );
};

const DuplicateElementModal = (props: DuplicateElementsProps) => {
    const {
        setShowModal,
        elementType,
        elementName,
        darkMode = false,
        elementId
    } = props;

    const { property } = useUrlParams();

    const dispatch = useDispatch();
    const elementsData = useSelector(selectElements);

    const elementTypeDisplayName = (() => {
        switch (elementType) {
            case ElementCategory.PAGE:
                return 'Page';
            case ElementCategory.RAIL:
                return 'Rail';
            case ElementCategory.WIDGET:
                return 'Tool';
        }
    })();

    const DuplicateValidationSchema = () => {
        return Yup.object().shape({
            elementName: Yup.string()
                .required('Required*')
                .trim()
                .min(3, 'Should be atleast 3 characters')
                .test(
                    'unique-name',
                    ` ${elementTypeDisplayName} with this name already exists`,
                    function (value) {
                        const { filteredElementNames } = this.parent;
                        return !filteredElementNames.includes(value);
                    }
                )
        });
    };

    const nameList = elementsData
        .map((e) => e.elements.map((elm) => elm.name))
        .flat();

    const formik = useFormik({
        initialValues: {
            elementName: generateUniqueName(nameList, `Copy of ${elementName}`),
            filteredElementNames: nameList
        },
        validateOnBlur: true,
        validationSchema: DuplicateValidationSchema,
        onSubmit: (e) => {}
    });

    const handleClose = () => {
        setShowModal(false);
    };

    const handleSubmit = (e: React.SyntheticEvent) => {
        e.preventDefault();
        dispatch(
            duplicateElement(
                property,
                elementId,
                formik.values.elementName,
                elementTypeDisplayName
            )
        );
        handleClose();
    };

    const dialogContentProps = {
        elementTypeDisplayName,
        handleSubmit,
        handleClose,
        formik,
        darkMode,
        elementType
    };

    return (
        <>
            {darkMode ? (
                <CanvasModalEditor onClose={handleClose}>
                    <DialogContent {...dialogContentProps} />
                </CanvasModalEditor>
            ) : (
                <CMPDialog
                    maxWidth="sm"
                    onClose={handleClose}
                    PaperProps={{
                        component: 'form',
                        onSubmit: handleSubmit
                    }}
                >
                    <DialogContent {...dialogContentProps} />
                </CMPDialog>
            )}
        </>
    );
};

export default DuplicateElementModal;
