import { get, isEmpty } from 'lodash';
import { PropertyGroupInterface } from '../redux/propertylist/model';

/**
 *
 * @param stringToBeSearchedUpon string
 * @param keyword string
 * @returns true with the keyword exist in stringToBeSearchedUpon
 */
export function search(
    stringToBeSearchedUpon: string,
    keyword: string
): boolean {
    return stringToBeSearchedUpon
        .toLowerCase()
        .includes(keyword.toLocaleLowerCase());
}

export function shallowSearch(arr: any[], searchKey: string, paths?: string[]) {
    return arr.filter((obj) => {
        const pathsToSearch = isEmpty(paths)
            ? Object.keys(obj)
            : (paths as string[]);

        return pathsToSearch.some((key) => {
            if (Array.isArray(obj[key])) return false;
            return get(obj, key)
                ?.toString()
                ?.toLowerCase()
                ?.trim()
                .includes(searchKey.toLowerCase());
        });
    });
}

export function searchPropertyGroupAndProperties(
    searchKeyword: string,
    propertyGroupList: PropertyGroupInterface[]
): PropertyGroupInterface[] {
    // searching for property groups
    const filteredPropertyGroupData = propertyGroupList.filter(
        (val: PropertyGroupInterface) => {
            return search(val.property_group_name, searchKeyword);
        }
    );

    //searching for properties
    const filteredPropertyGroupIds = filteredPropertyGroupData.map(
        (val: PropertyGroupInterface) => val.property_group_id
    );

    const propertyGroupDataWithoutSearchedGroup = propertyGroupList.filter(
        (val) => !filteredPropertyGroupIds.includes(val.property_group_id)
    );

    if (propertyGroupDataWithoutSearchedGroup) {
        for (const propertyGroup of propertyGroupDataWithoutSearchedGroup) {
            const filteredProperties = propertyGroup.properties.filter(
                (p) =>
                    search(p.name, searchKeyword) ||
                    search(p.smartbox_id, searchKeyword) ||
                    search(p.property_type_display_name, searchKeyword)
            );
            const propertyGroupWithFilteredProperty: PropertyGroupInterface = {
                ...propertyGroup,
                properties: filteredProperties
            };
            if (!isEmpty(filteredProperties))
                filteredPropertyGroupData.push(
                    propertyGroupWithFilteredProperty
                );
        }
    }

    return filteredPropertyGroupData;
}
