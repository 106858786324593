import React, { useState } from 'react';
import ValidationTexts, { ValidationTextsProps } from './cmpValidationTexts';
import { PasswordTextFieldProps } from './cmpPasswordTextField';
import { Box } from '@mui/material';
import CMPTextField from './cmpTextField';

const CMPValidationTextField = (
    props: PasswordTextFieldProps & ValidationTextsProps
) => {
    const {
        variation,
        shrink,
        required,
        id,
        label,
        value,
        onChange,
        error,
        options,
        columns,
        onBlur
    } = props;

    const [isFocused, setIsFocused] = useState(false);

    return (
        <Box>
            <CMPTextField
                variation={variation}
                id={id}
                shrink={shrink}
                required={required}
                label={label}
                value={value}
                onChange={onChange}
                onFocus={() => setIsFocused(true)}
                onBlur={(e) => {
                    setIsFocused(false);
                    if (onBlur) onBlur(e);
                }}
                error={error}
            />
            <ValidationTexts
                show={isFocused || error}
                options={options}
                columns={columns}
            />
        </Box>
    );
};

export default CMPValidationTextField;
